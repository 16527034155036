import React, { useEffect, useMemo, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import SalesOverview from "./SalesOverviewPage";
import { httpAdmin } from "../../Apis/commonApis";
import LeadsManagmentTable from "./LeadsManagmentTable";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { useDispatch, useSelector } from "react-redux";
import { getAccessList } from "../../utils/CommonFunctions";
import NoAccessMsg from "../../components/NoAccessMsg";
import { createAccessEvent } from "../../redux-toolkit/reducer/eventLogsReducer";

export default function SalesRoutes(props) {
  const { filterType, dateRange } = props;

  const { accessRoles } = useSelector((state) => state.caresiteDataReducer);
  const moduleAccess = useMemo(() => {
    const findCurrentModule = accessRoles?.find(
      (item) => item.moduleName === "Sales Funnel"
    );
    const accesssList = getAccessList(findCurrentModule?.children);
    return accesssList;
  }, [accessRoles]);

  let { topicId } = useParams();

  const [rows, setRows] = useState([]);
  const [leadData, setLeadsData] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    // create access event log
    const logData = {
      documentType: `sales funnel`,
      description: `Sales Funnel page visited`,
      // documentId: careteamId,
    };
    dispatch(createAccessEvent(logData));
  }, [topicId]);

  useEffect(() => {
    getLeads();
  }, []);

  const getLeads = async () => {
    const res = await httpAdmin(
      `getLeads?careHomeId=${currentCareSiteId}`,
      "get",
      null,
      null
    );
    if (res?.status) {
      setLeadsData(res?.data);
      setRows(res?.data?.data);
    }
  };

  return (
    <>
      {topicId === "overview" && (
        <>
          {moduleAccess?.["Sales Overview"] === "full" ||
          moduleAccess?.["Sales Overview"] === "view" ? (
            <SalesOverview
              filterType={filterType}
              dateRange={dateRange}
              overviewAccess={moduleAccess?.["Sales Overview"]}
            />
          ) : (
            <NoAccessMsg />
          )}
        </>
      )}
      {topicId === "leads" && (
        <>
          {moduleAccess?.["Leads Management"] === "full" ||
          moduleAccess?.["Leads Management"] === "view" ? (
            <LeadsManagmentTable
              rows={rows}
              leadData={leadData}
              getLeads={getLeads}
              leadAccess={moduleAccess?.["Leads Management"]}
            />
          ) : (
            <NoAccessMsg />
          )}
        </>
      )}
    </>
  );
}
