import imageCompression from "browser-image-compression";
import moment from "moment";
let today = new Date();

export const disablePastDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

export const getMondayDate = (date) => {
  let currentDay = date ? new Date(date).getDay() : today.getDay();
  let daysSinceMonday = currentDay > 0 ? currentDay - 1 : 6;
  let mondayDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - daysSinceMonday
  );
  return mondayDate;
};

export const sendDateFormat = (date) => {
  let recievedDate = new Date(date);
  let sendDate = recievedDate?.toJSON()?.split("T")[0];
  return sendDate;
};

export const formatDate = (date) => {
  // moment().local() is a correct way. But this can do in a single line. Here is how
  // var local = moment.utc("2015-10-24 20:00").local().format('YYYY-MMM-DD h:mm A');
  if (date) {
    const userTimeZone =
      Intl.DateTimeFormat().resolvedOptions().timeZone ?? "Asia/Kolkata";
    let sendDate = moment(date).tz(userTimeZone).format("YYYY-MM-DD");
    return sendDate;
  } else {
    return null;
  }
};

export const getMonthAgoDate = () => {
  let monthAgoDate = new Date();
  let monthAgo = monthAgoDate.getMonth() - 1;
  monthAgoDate.setMonth(monthAgo);
  return monthAgoDate;
};

export function getWeekStartAndEndDates(inputDate) {
  const date = new Date(inputDate);
  const dayOfWeek = date.getDay();
  const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust when the day is Sunday

  const weekStartDate = new Date(date.setDate(diff));
  const weekEndDate = new Date(weekStartDate);
  weekEndDate.setDate(weekStartDate.getDate() + 6);

  return {
    start: formatDate(weekStartDate),
    end: formatDate(weekEndDate),
  };
}

export const formatDateToDDMM = (date) => {
  let recievedDate = new Date(date);
  const day = String(recievedDate.getDate()).padStart(2, "0");
  const month = String(recievedDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
  return `${day}-${month}`;
};

export const FirstCharacterCapital = (string) => {
  return (
    string?.length > 0 &&
    string?.split("")[0]?.toUpperCase() + string?.split("")?.splice(1)?.join("")
  );
};

export function toTitleCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const convertToTimeZone = (time) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let localTime = "";
  if (time && userTimeZone) {
    localTime = new Date(time).toLocaleString("en-US", {
      timeZone: userTimeZone,
      hour12: false, // Set to false for 24-hour format
      hour: "2-digit",
      minute: "2-digit",
      // second: '2-digit',
    });
  }
  return localTime !== "" ? localTime : "-";
};

export const convertToTimeZoneFullTime = (time) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let localTime;
  if (time && userTimeZone) {
    localTime = moment.tz(time, userTimeZone).format("MMMM D, YYYY h:mm A");
  }
  return localTime ?? null;
};

export function currentTimeZone() {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return userTimeZone;
}

export function isDateTimeGreaterThanCurrent(dateTimeString, currentdate) {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDateTime = new Date(currentdate);
  const providedDateTime = new Date(dateTimeString);

  const targetTimeZoneDateTime = new Date(
    providedDateTime.toLocaleString("en-US", { userTimeZone })
  );

  const targetTimeZoneCurrentDateTime = new Date(
    currentDateTime.toLocaleString("en-US", { userTimeZone })
  );

  return targetTimeZoneDateTime > targetTimeZoneCurrentDateTime;
}

export function convertTimeToUserTimeZone(time, timezone) {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = new Date();
  // const options = { hour: 'numeric', minute: 'numeric', timeZone: timezone ?? userTimeZone};

  const convertedTime = new Date(
    date.toLocaleString("en-US", { timeZone: timezone || userTimeZone })
  );
  convertedTime.setHours(
    Number(time?.split(":")[0]),
    Number(time?.split(":")[1]),
    0,
    0
  );

  return moment(convertedTime).format("HH:mm");
  // return convertedTime.toLocaleTimeString('en-US', options);
}

export function getCurrentDateTime() {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentTime = moment().tz(userTimeZone).format("HH:mm");
  const currentDate = moment().tz(userTimeZone).format("YYYY-MM-DD");

  return { date: currentDate, time: currentTime };
}

export function filterEmptyKeys(obj) {
  const filteredObj = {};
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
      filteredObj[key] = obj[key];
    }
  }
  return filteredObj;
}

export function getChangedKeysVals(obj1, obj2) {
  const changedKeys = {};

  for (const key in obj2) {
    if (obj1[key]?.toString() !== obj2[key]) {
      if (extraCheck(obj1[key], obj2[key])) {
        changedKeys[key] = obj2[key];
      }
    }
  }
  return changedKeys;
}

const extraCheck = (val1, val2) => {
  if (!val1 && val2 === "") {
    return false;
  }
  return true;
};

export async function compressImage(event) {
  const imageFile = event[0];
  // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
  console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    // Create a new blob with the compressed file
    const compressedBlob = new Blob([compressedFile]);

    // Create a new File with the appropriate file name and extension
    const compressedImage = new File([compressedBlob], `${imageFile.name}`, {
      type: compressedBlob.type,
    });
    return compressedImage; // write your own logic
  } catch (error) {
    return error;
  }
}
export function combineDateTime(date, time, timezone) {
  const combinedDateTimeString = `${date}T${time}`;

  const combinedDateTime = moment.tz(
    combinedDateTimeString,
    "YYYY-MM-DDTHH:mm",
    timezone
  );

  // Format the combined date and time according to the user's timezone
  const formattedDateTime = combinedDateTime.format();

  return formattedDateTime;
}

export function getCurrentTimeZone() {
  const options = { timeZoneName: "short" };
  const timeZone = Intl.DateTimeFormat(undefined, options).resolvedOptions()
    .timeZone;
  return timeZone;
}

export function limitToTwoDecimal(number) {
  return number > 0 ? Math.round(number * 100) / 100 : 0;
}

export function addMinutesToTime(time, minutesToAdd) {
  // Parse the input time
  const [hours, minutes] = time.split(":").map(Number);

  // Calculate the total minutes
  const totalMinutes = hours * 60 + minutes;

  // Add minutes
  const newTotalMinutes = totalMinutes + minutesToAdd;

  // Calculate new hours and minutes
  const newHours = Math.floor(newTotalMinutes / 60);
  const newMinutes = newTotalMinutes % 60;

  // Format the result
  const formattedResult = `${String(newHours).padStart(2, "0")}:${String(
    newMinutes
  ).padStart(2, "0")}`;

  return formattedResult;
}

export function generateExcelTable(data, columnsToInclude) {
  let htmlTable = "<thead><tr>";

  columnsToInclude.forEach((column) => {
    htmlTable += "<th>" + column.header + "</th>";
  });

  htmlTable += "</tr></thead><tbody>";

  data.forEach((row) => {
    htmlTable += "<tr>";
    columnsToInclude.forEach((column) => {
      let dataToAdd = row[column.key] ?? "";
      htmlTable += '<td class="column-cell">' + dataToAdd + "</td>";
    });
    htmlTable += "</tr>";
  });

  htmlTable += "</tbody>";
  return htmlTable;
}

export function generateExcelTableNew(data) {
  if (data.length < 1) {
    return "";
  }
  let htmlTable = "<thead><tr>";

  Object.keys(data?.[0])?.forEach((column) => {
    htmlTable += "<th>" + ` ${column} ` + "</th>";
  });

  htmlTable += "</tr></thead><tbody>";

  data?.forEach((row) => {
    htmlTable += "<tr>";
    Object.keys(data?.[0])?.forEach((column) => {
      let dataToAdd = row[column] ?? "";
      htmlTable += '<td class="column-cell">' + ` ${dataToAdd} ` + "</td>";
    });
    htmlTable += "</tr>";
  });

  htmlTable += "</tbody>";
  return htmlTable;
}

export function handleDataPrint(excelData) {
  const excelContainer = document.createElement("div");
  excelContainer.innerHTML =
    "<table>" + generateExcelTableNew(excelData) + "</table>";

  const printWindow = window.open("", "", "width=600,height=600");
  printWindow.document.open();
  printWindow.document.write(excelContainer.innerHTML);
  printWindow.document.close();
  printWindow.print();
}

export function sortByLatestDateFirst(array, dateParam) {
  return array?.slice()?.sort((a, b) => {
    // Handle empty dates
    if (!a[dateParam] && !b[dateParam]) return 0;
    if (!a[dateParam]) return 1;
    if (!b[dateParam]) return -1;

    // Convert[dateParam] strings to Date objects
    const dateA = new Date(a[dateParam]);
    const dateB = new Date(b[dateParam]);

    // Sort by date (latest first)
    return dateB - dateA;
  });
}

export function sortByDate(array, dateParam, sortOrder) {
  return array?.slice()?.sort((a, b) => {
    // Handle empty dates
    if (!a[dateParam] && !b[dateParam]) return 0;
    if (!a[dateParam]) return 1;
    if (!b[dateParam]) return -1;

    // Convert[dateParam] strings to Date objects
    const dateA = new Date(a[dateParam]);
    const dateB = new Date(b[dateParam]);

    if (sortOrder === "latestLast") {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  });
}
export function getFullName(dataObj) {
  if (dataObj?.firstName) {
    return dataObj?.firstName
      ? `${dataObj?.firstName} ${dataObj?.lastName}`
      : "deleted user";
  }
  return dataObj?.first_Name
    ? `${dataObj?.first_Name} ${dataObj?.last_Name}`
    : "deleted user";
}

export function getObjectKeysInString(dataObj) {
  if (dataObj) {
    return Object.keys(dataObj)?.join(", ");
  }
  return "-";
}

export function getObjectValsInString(dataObj) {
  if (dataObj) {
    return Object.values(dataObj)?.join(", ");
  }
  return "-";
}

export function getPageHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );
}

export function getAccessList(dataArray) {
  const accesssList = dataArray?.reduce((acc, obj) => {
    acc[obj.moduleName] = obj?.access;
    return acc;
  }, {});
  return accesssList;
}

export function sortDataByKey(data, key) {
  const sortedData = data.sort((a, b) => {
    // If _id property exists in both objects, compare them
    if (a[key] !== undefined && b[key] !== undefined) {
      return a[key] - b[key];
    } else if (a[key] !== undefined) {
      return -1;
    } else if (b[key] !== undefined) {
      return 1;
    } else {
      return 0;
    }
  });
  return sortedData;
}
