import React, { useEffect, useMemo } from 'react';
import EditCareTeam from "./EditCareTeam";
import AddEditCareTeamDocs from "./AddEditCareTeamDocs";
import AddEditCareTraining from "./AddEditCareTraining";
import CareTeamJournalAsApp from "./CareTeamJournalAsApp";
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessList } from '../../utils/CommonFunctions';
import NoAccessMsg from '../../components/NoAccessMsg';
import { createAccessEvent } from '../../redux-toolkit/reducer/eventLogsReducer';

export default function CareteamRoutes() {

    const { accessRoles } = useSelector((state) => state.caresiteDataReducer);
    const moduleAccess = useMemo(() => {
        const findCurrentModule = accessRoles?.find(item => item.moduleName === 'CareTeam');
        const accesssList = getAccessList(findCurrentModule?.children);
        return accesssList;
    }, [accessRoles]);

    let { topicId } = useParams();

    const location = useLocation();
    const careteamId = location.state?.careteamId;

    const dispatch = useDispatch();
    useEffect(() => {
      // create access event log
      const logData = {
        documentType: `careteam ${topicId}`,
        description: `Careteam ${topicId} page visited`,
        documentId: careteamId,
      };
      dispatch(createAccessEvent(logData));
    }, [topicId]);

    return (
        <>
            {topicId === "profile" && <>
                {(moduleAccess?.Profile === 'full' || moduleAccess?.Profile === 'view') ?
                    <EditCareTeam
                        careteamId={careteamId}
                        Profileaccess={moduleAccess?.Profile}
                    /> : <NoAccessMsg />}
            </>}
            {topicId === "trainings" && <>
                {(moduleAccess?.Training === 'full' || moduleAccess?.Training === 'view') ?
                    <AddEditCareTraining
                        careteamId={careteamId}
                        Trainingsaccess={moduleAccess?.Training}
                    /> : <NoAccessMsg />}
            </>}
            {topicId === "docs" && <>
                {(moduleAccess?.Docs === 'full' || moduleAccess?.Docs === 'view') ?
                    <AddEditCareTeamDocs
                        careteamId={careteamId}
                        Docsaccess={moduleAccess?.Docs}
                    /> : <NoAccessMsg />}
            </>}
            {topicId === "journal" && <>
                {(moduleAccess?.Journal === 'full' || moduleAccess?.Journal === 'view') ?
                    <CareTeamJournalAsApp
                        careteamId={careteamId}
                        JournalAccess={moduleAccess?.Journal}
                    /> : <NoAccessMsg />}
            </>}
        </>
    )
}