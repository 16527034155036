import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Scheduler from "./scheduleMed/Medscheduler";
import Medicines from "./Medicines";
import axios from "axios";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import { getAccessList, getFullName } from "../../utils/CommonFunctions";
import { useDispatch, useSelector } from "react-redux";
import NoAccessMsg from "../../components/NoAccessMsg";
import InsideMedicineTable from "./insideMedicineTable";
import { createAccessEvent } from "../../redux-toolkit/reducer/eventLogsReducer";

export default function MedicineRoutes(props) {
  const { searchText } = props;

  const { accessRoles } = useSelector((state) => state.caresiteDataReducer);
  const moduleAccess = useMemo(() => {
    const findCurrentModule = accessRoles?.find(
      (item) => item.moduleName === "Medicines"
    );
    const accesssList = getAccessList(findCurrentModule?.children);
    return accesssList;
  }, [accessRoles]);

  let { topicId } = useParams();

  const [rowsData, setrowData] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [allMedicines, setAllMedicines] = useState([]);
  const [dmdUpdationDate, setDmdUpdationDate] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    // create access event log
    const logData = {
      documentType: `emar`,
      description: `eMAR page visited`,
      // documentId: careteamId,
    };
    dispatch(createAccessEvent(logData));
  }, [topicId]);

  useEffect(() => {
    getClientRowData();
    getAllMedicines();
  }, []);

  const getClientRowData = () => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/getClient?careHomeId=${currentCareSiteId}&type=medicine`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    })
      .then((res) => {
        if (res?.data?.clientListing) {
          setStockData(res?.data?.clientListing);
          let ClientData = res.data.clientListing.clientListing;
          let ownersData = [];
          let clients = [];
          ClientData.forEach((element, index) => {
            if (element.clientStatus.toLowerCase() !== "closed") {
              clients.push({
                value: element._id,
                label: getFullName(element),
              });
            }
            if (element?.medication) {
              element?.medication?.forEach((elem) => {
                ownersData.push({
                  no: index,
                  careHomeId: element?.careHomeId,
                  custumMedId: elem?.customMedicineId,
                  cid: element?._id,
                  id: elem._id,
                  image: element?.image,
                  name: getFullName(element),
                  location: element?.location,
                  advancedirective: element?.advance_Directive,
                  allergies: element?.medical_Conditions
                    ?.map((item) =>
                      item.type === "Allergy" ? item.name : null
                    )
                    .filter((item) => item !== null)
                    .join(","),
                  conditions: element?.medical_Conditions
                    ?.map((item) =>
                      item.type === "Condition" ? item.name : null
                    )
                    .filter((item) => item !== null)
                    .join(","),
                  medication: elem,
                  medName: elem?.medName,
                  PRNStatus: elem?.prn === "Yes" ? true : false,
                  InStock: elem?.inventory?.remaining_qty,
                  StockLevel: elem?.inventory?.stockLevel,
                  noofmedicines: 0,
                  status: elem?.status,
                  isActive:
                    elem.status.toLowerCase() === "active" ? true : false,
                  // redirectionLink: `/superadmin/clientdetails/${element._id}`,
                });
              });
            }
          });
          setClientList(clients);
          setrowData(ownersData);
        }
      })
      .catch((error) => console.log(`Error: ${error}`));
  };

  const getAllMedicines = () => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/getAllDMDMedicines`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    })
      .then((res) => {
        let medicineAllData = res.data.data;
        setDmdUpdationDate(medicineAllData[0]?.createdAt);
        let medicines = [];
        for (let i = 0; i < medicineAllData.length; i++) {
          medicines.push({
            label: medicineAllData[i].DESC._text,
            value: medicineAllData[i].APID._text,
          });
        }
        setAllMedicines(medicines);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };

  return (
    <>
      {topicId === "schedule" && (
        <>
          {moduleAccess?.Scheduler === "full" ||
          moduleAccess?.Scheduler === "view" ? (
            <div className="mt-1">
              <Scheduler
                text={searchText}
                SchedulerAccess={moduleAccess?.Scheduler}
              />
            </div>
          ) : (
            <NoAccessMsg />
          )}
        </>
      )}

      {topicId === "medicines" && (
        <>
          {moduleAccess?.Medicines === "full" ||
          moduleAccess?.Medicines === "view" ? (
            <Medicines
              searchText={searchText}
              rowsData={rowsData}
              clientList={clientList}
              stockData={stockData}
              getClientRowData={getClientRowData}
              medAccess={moduleAccess?.Medicines}
              allMedicines={allMedicines}
              dmdUpdationDate={dmdUpdationDate}
            />
          ) : (
            <NoAccessMsg />
          )}
        </>
      )}

      {topicId === "medicine-detail" && (
        <>
          {moduleAccess?.Medicines === "full" ||
          moduleAccess?.Medicines === "view" ? (
            <InsideMedicineTable
              allMedicines={allMedicines}
              medAccess={moduleAccess?.Medicines}
            />
          ) : (
            <NoAccessMsg />
          )}
        </>
      )}
    </>
  );
}
