import React, { useState, useEffect, useRef, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
import "../Clients/clients.css";
import axios from "axios";
import Select from "react-select";
import ClientNewTable from "./ClientNewTable";
import { Controller, useForm } from "react-hook-form";
// import XLSX from "xlsx";
import {
  Modal,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import datechangeHandler from "../../utils/datechangehandler";
import swal from "sweetalert";
import { fetch4, httpAdmin, limitToTwoDecimal } from "../../Apis/commonApis";
import { ClientStatus } from "../../constants/constants";
import { IoMdInformationCircle } from "react-icons/io";
import { AiFillPrinter } from "react-icons/ai";
import JsonDownload from "../../components/DownloadExcel/DownloadExcel";
import { BsSearch } from "react-icons/bs";
import {
  currentCareSiteId,
  getCareSiteListing,
  selectCareSite,
} from "../../redux-toolkit/reducer/switchSites";
import DatePicker from "react-datepicker";
import {
  compressImage,
  formatDate,
  getFullName,
  getMondayDate,
  getMonthAgoDate,
  handleDataPrint,
  sortByDate,
} from "../../utils/CommonFunctions";
import { ShowInfoSwal, ShowSwal } from "../../utils/alertSwal";
import { useDispatch, useSelector } from "react-redux";
import AwsLocation from "../../utils/AwsLocation";
import clientUpload from "../../files/clientUpload.xlsx";
import { createAccessEvent } from "../../redux-toolkit/reducer/eventLogsReducer";

let today = new Date();
let monthAgoDate = getMonthAgoDate();

function Clients() {
  const { currentPlanName, currentCareSiteData, accessRoles } = useSelector(
    (state) => state.caresiteDataReducer
  );
  const isPlanBase = currentPlanName === "Base";
  const currentModuleAccess = useMemo(() => {
    const findCurrentModule = accessRoles?.find(
      (item) => item.moduleName === "Clients"
    );
    return findCurrentModule?.children;
  }, [accessRoles]);

  const {
    register,
    handleSubmit,
    unregister,
    reset,
    formState: { errors },
  } = useForm();

  const {
    register: registerS,
    handleSubmit: handleSubmitS,
    reset: resetS,
    formState: { errors: errorsS },
    control: control,
  } = useForm();

  const handleDownloadClick = () => {
    const link = document.createElement("a");
    link.href = clientUpload;
    link.download = "clientUpload.xlsx";
    link.click();
  };

  let history = useHistory();
  const divRef = useRef(null);
  // let [plantype, setplantype] = useState("");

  // setTimeout(() => {
  //   setplantype(JSON.parse(localStorage.getItem("userData")).SubscriptionPlan);
  // }, 800);

  const [rowData, setRowData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [options, setoptions] = useState([]);
  const [location, setlocation] = useState("");
  const [permanentAddresslocation, setpermanentAddresslocation] =
    useState(null);
  const [locErrorMsg, setlocErrorMsg] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [isPermanent, setIsPermanent] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [careSiteData, setCareSiteData] = useState(null);
  const [getLatest, setGetLatest] = useState(0);
  const [filterType, setFilterType] = useState("week");
  const [dateRange, setDateRange] = useState([monthAgoDate, new Date()]);
  const [startDate, endDate] = dateRange;

  let user = JSON.parse(localStorage.getItem("userData"));

  const dispatch = useDispatch();
  useEffect(() => {
    // create access event log
    const logData = {
      documentType: "clients",
      description: "Clients page visited",
    };
    dispatch(createAccessEvent(logData));
  }, []);

  useEffect(() => {
    if (
      currentCareSiteData &&
      currentCareSiteData?.carefacility === "facalities"
    ) {
      let siteLat = currentCareSiteData?.loc?.coordinates?.[1];
      let siteLong = currentCareSiteData?.loc?.coordinates?.[0];
      if (siteLat && siteLong && currentCareSiteData?.careSiteAddress) {
        setlocation({
          Label: currentCareSiteData?.careSiteAddress,
          latitude: siteLat,
          longitude: siteLong,
        });
        setIsPermanent(true);
      }
    }
  }, [currentCareSiteData]);

  useEffect(() => {
    if (getCareSiteListing && currentCareSiteId) {
      let siteData = getCareSiteListing?.find(
        (item) => item?._id === currentCareSiteId
      );
      if (!siteData) {
        siteData = user.subscriptionPlanData.find(
          (site) => site._id === currentCareSiteId
        );
      }
      setCareSiteData(siteData);
    }
  }, [getCareSiteListing]);

  function awsLocationHandler(locVal) {
    setlocation(locVal);
    if (locVal) setlocErrorMsg("");
  }

  function permanentLocHandler(locVal) {
    setpermanentAddresslocation(locVal);
    if (locVal) setlocErrorMsg("");
  }

  const resetForm = () => {
    setlocation(null);
    setlocErrorMsg("");
    setIsOpen(false);
  };

  async function add_new_resident({
    lastName,
    firstName,
    ContractStartDate,
    DOB,
    uploadFile,
    roomNo,
  }) {
    if (!location) {
      setlocErrorMsg("location");
      return;
    } else if (!isPermanent && !permanentAddresslocation) {
      setlocErrorMsg("permanent");
      return;
    }
    let data = new FormData();
    data.append("location", location?.Label);
    data.append("lattitude", location?.latitude);
    data.append("longitude", location?.longitude);
    data.append(
      "permanentAddress",
      isPermanent ? location?.Label : permanentAddresslocation?.Label
    );
    data.append("last_Name", lastName);
    data.append("first_Name", firstName);
    data.append("isPermanent", isPermanent ? "true" : "false");
    data.append("contractStartdate", ContractStartDate);
    data.append("careHomeId", currentCareSiteId);
    data.append("DOB", DOB);
    if (uploadFile[0]) {
      let compressedImg = await compressImage(uploadFile);
      data.append("image", compressedImg);
    }
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/create_client`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === false) {
          swal("Failed", response.data.message, "error").then((value) => {
            return;
          });
        }
        swal("Success", response.data.message, "success").then((value) => {
          resetForm();
          setIsOpen(false);
          history.push(`/admin/clients/details/profile`, {
            clientId: response?.data?.data?._id,
          });
        });
      })
      .catch(function (error) {
        if (error.response) {
          swal("Failed", error.response.data.message, "error");
        } else if (error.request) {
        } else {
          swal("Failed", error.message, "error");
        }
      });
  }

  useEffect(() => {
    if (filterType === "custom" && endDate) {
      getClientRowData(formatDate(startDate), formatDate(endDate));
    } else if (filterType === "week") {
      let mondayDate = getMondayDate();
      getClientRowData(formatDate(mondayDate), formatDate(today));
    } else if (filterType === "today") {
      // getClientRowData();
    }
  }, [filterType, dateRange, getLatest]);

  const getClientRowData = (start, end) => {
    let urlID = JSON.parse(localStorage.getItem("userData"));
    axios({
      url: `${process.env.REACT_APP_BASEURL}/getClient?careHomeId=${currentCareSiteId}&startDate=${start}&endDate=${end}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    })
      .then((res) => {
        const ClientData = res?.data?.clientListing?.clientListing ?? [];
        let clientsArr = [];
        let clientListingData = [];
        let clientDownloadData = [];
        ClientData?.forEach((elem, index) => {
          let ishrsServiceDay =
            elem.invoiceType === "Fixed Period" ? true : false;
          let hrs_serv = elem?.hrs_of_service
            ? `${limitToTwoDecimal(elem?.hrs_of_service)} ${
                ishrsServiceDay ? "Days" : "Hrs"
              }`
            : "0";
          if (elem.clientType === "group") {
            hrs_serv = elem?.hrs_of_service + " " + "Hrs";
          }
          let groupClients = elem.clientGroup?.map(
            (item) => `${item.first_Name} ${item.last_Name}`
          );
          let clintName = elem?.first_Name
            ? getFullName(elem)
            : `${elem.groupName} (Clients - ${groupClients.join(", ")})`;
          clientListingData.push({
            id: elem?._id,
            advance_Directive: elem?.advance_Directive || "-",
            careHomeId: elem?.careHomeId,
            citizenship: elem?.citizenship || " ",
            status: elem?.clientStatus,
            contractStartdate: datechangeHandler(elem?.contractStartdate),
            name: clintName,
            roomNo:
              currentCareSiteData?.carefacility == "facalities"
                ? elem?.roomNo
                : "",
            first_Name: elem?.first_Name
              ? elem?.first_Name
              : `${elem.groupName} (${groupClients.join(", ")})`,
            last_Name: elem?.last_Name ? elem?.last_Name : "",
            groupName: elem?.groupName ? elem?.groupName : "",
            clientGroup: elem.clientGroup ?? [],
            loc: elem.loc ?? [],
            clientType: elem.clientType,
            image: elem?.image ?? "",
            location: elem?.location,
            brief: elem?.brief || "",
            hrs_of_service: hrs_serv,
            dateOfMovingOut: elem?.dateOfMovingOut || "",
            overdueTasks: elem?.overdueTasks ? elem?.overdueTasks : "0",
            toDos: elem?.toDos ? elem?.toDos : "",
            createdAt: elem.createdAt,
          });
          if (elem.clientType === "client" && elem.clientStatus === "active") {
            clientsArr.push({
              value: elem._id,
              label: elem.first_Name,
              location: elem.location,
              loc: elem.loc,
            });
          }
          if (elem.clientType === "client") {
            let authPersons = elem.authrisedPerson?.map((item) => item.name);
            clientDownloadData.push({
              ["First Name"]: elem.first_Name,
              ["Last Name"]: elem.last_Name ?? "",
              ["Contract Start Date"]: elem.contractStartdate ?? "",
              ["Contract End Date"]: elem.contractEnddate ?? "",
              ["Date of Birth"]: elem.DOB ?? "",
              ["Blood Group"]: elem.bloodGroup ?? "",
              ["Profession"]: elem.profession ?? "",
              ["Marital Status"]: elem.marital_Status ?? "",
              ["Religion"]: elem.religion ?? "",
              ["Ethnicity"]: elem.ethnicity ?? "",
              ["Citizenship"]: elem.citizenship ?? "",
              ["Language"]: elem.language ?? "",
              ["Height"]: elem.height ?? "",
              ["Weight"]: elem.weight ?? "",
              ["Eye Color"]: elem.eye_Color ?? "",
              ["Hair Color"]: elem.hair_Color ?? "",
              ["istinguishing Mark"]: elem.distinguishing_Mark ?? "",
              ["Brief Background"]: elem.brief ?? "",
              ["Address"]: elem.location ?? "",
              ["Permanent Address"]: elem.permanentAddress ?? "",
              ["Mental Capacity to Consent"]: elem.consentMentalCapacity ?? "",
              ["Power of Attorney in Place"]: elem.consentPOA ?? "",
              ["Authorized Person"]: authPersons ? authPersons.join(", ") : "",
              ["Advanced Directive"]: elem.advance_Directive ?? "",
            });
          }
        });
        setRowData(sortByDate(clientListingData, "createdAt", "latestLast"));
        setoptions(clientsArr);
        setDownloadData(clientDownloadData);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };

  const [clientsForGrp, setClientsForGrp] = useState([]);
  const [groupName, setgroupName] = useState("");
  const [groupErr, setgroupErr] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [openGroupModal, setOpenGroupModal] = useState(false);

  // -----------  Delete Clients Popup (Show and Hide Handler) Starts ------------ //

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleCloseDeletePopup = () => setIsDeleteOpen(false);
  const handleShowDeletePopup = () => setIsDeleteOpen(true);

  // -----------  Change clients Status Popup (Show and Hide Handler) Starts ------------ //

  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const handleCloseStatusPopup = () => setIsStatusOpen(false);
  const handleShowStatusPopup = () => setIsStatusOpen(true);

  const handleChange = (e) => {
    setClientsForGrp(e);
    setgroupErr("");
  };

  const createclientGroup = () => {
    let clientId = [];
    let clientName = [];
    if (clientsForGrp.length < 1 || groupName === "") {
      setgroupErr(groupName === "" ? "groupName" : "selectClient");
      return;
    }
    clientsForGrp.forEach((items, index) => {
      clientId.push(items.value);
      clientName.push(items.label);
    });

    let data = JSON.stringify({
      adminId: careSiteData.adminId,
      careHomeId: currentCareSiteId,
      groupName: groupName,
      longitude: clientsForGrp[0].loc?.coordinates[0],
      lattitude: clientsForGrp[0].loc?.coordinates[0],
      location: clientsForGrp[0]?.location,
      clientIds: clientId,
    });

    const apiEnd = editGroup
      ? `edit_client/${selectedRows[0]}`
      : "createGroupclient";
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/${apiEnd}`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (res) {
        handleGpModalClose();
        setGetLatest(getLatest + 1);
        ShowSwal(res.data.status, res.data.message);
      })
      .catch(function (error) {
        swal("Failed", "Oops, Something went wrong", "error");
      });
  };
  // ---------------------     delete multiple clients api    ----------------------------------  //

  async function deleteClients() {
    const result = await httpAdmin(
      `deleteClient`,
      "post",
      { clientId: selectedRows },
      null
    );
    swal(
      result.status ? "Success" : "Failed",
      result.message,
      result.status ? "success" : "error"
    ).then((value) => {
      handleCloseDeletePopup();
      setGetLatest(getLatest + 1);
      return;
    });
  }
  // ---------------------  Change client status api    ----------------------------------  //
  const [clientStatus, setClientStatus] = useState("");

  async function changeClienStatus(props) {
    // clientStatus : active/closed/moving_out
    let clintsInGroups = [];
    rowData.forEach((elem) => {
      clintsInGroups = [
        ...clintsInGroups,
        ...elem.clientGroup.map((item) => item._id ?? ""),
      ];
    });
    let isClientInGroup = false;
    selectedRows.forEach((elem) => {
      if (clintsInGroups.includes(elem)) {
        isClientInGroup = true;
      }
    });
    if (isClientInGroup) {
      ShowInfoSwal("Please first remove selected clients from groups!");
    } else {
      const data = {
        clientId: selectedRows,
        clientStatus: props.clientStatus.value,
      };
      if (clientStatus === "moving_out") {
        data.dateOfMovingOut = props.dateOfMovingOut;
      }
      try {
        const result = await fetch4(
          `${process.env.REACT_APP_BASEURL}/markClientStatus`,
          data,
          { Authorization: localStorage.getItem("care_admin_token") }
        );

        swal(
          result.status ? "Success" : "Failed",
          result.message,
          result.status ? "success" : "error"
        ).then((value) => {
          handleCloseStatusPopup();
          setGetLatest(getLatest + 1);
          return;
        });
      } catch (e) {
        swal("Failed", "Oops, Something went wrong", "error");
      }
    }
  }

  function customDateChangeHandler(update) {
    setDateRange(update);
  }

  const fileInputRef = useRef(null);
  const openFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const [loader, setLoader] = useState(false);
  function onFileUpload(e) {
    let file = e.target?.files[0];
    if (file) {
      setLoader(true);
      uploadFileHandler(file);
    }
  }

  const uploadFileHandler = async (file) => {
    let body = new FormData();
    body.append("file", file);
    body.append("adminId", careSiteData.adminId);
    body.append("careHomeId", currentCareSiteId);
    const result = await httpAdmin(`uploadClient`, "post", body, "formdata");
    setLoader(false);
    setGetLatest(getLatest + 1);
    ShowSwal(result.status, result.message);
  };

  function handleGpModalClose() {
    setOpenGroupModal(false);
    setgroupName("");
    setEditGroup(false);
    setOpenGroupModal(false);
    setClientsForGrp([]);
  }

  function handleEditGroupClick() {
    const selectedGrpData = rowData.find((item) => item.id === selectedRows[0]);
    if (selectedRows.length === 1 && selectedGrpData?.clientType === "group") {
      console.log(selectedGrpData, "selectedGrpData");
      setClientsForGrp(
        selectedGrpData?.clientGroup.map((item) => ({
          value: item._id,
          label: item.first_Name,
          location: selectedGrpData.location,
          loc: selectedGrpData.loc,
        }))
      );
      setgroupName(selectedGrpData.groupName.slice(6));
      setEditGroup(true);
      setOpenGroupModal(true);
    } else {
      ShowSwal(
        false,
        `Select ${selectedRows.length > 1 ? "only" : "atleast"} one ${
          selectedGrpData?.clientType === "group" ? "" : "Group"
        } to edit!`
      );
    }
  }

  const handleRowSelection = (rowId) => {
    setSelectedRows(rowId);
  };

  const filteredOptions =
    clientsForGrp.length > 0
      ? options?.filter((item) => item.location === clientsForGrp[0].location)
      : options;

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body" ref={divRef}>
              {/* <h4 className="card-title">
                    Clients
                  </h4> */}
              <div className="row mb-2">
                <div className=" col-md-2">
                  <AiFillPrinter
                    className="me-3 ms-1 curser_point"
                    onClick={() => handleDataPrint(downloadData)}
                  />
                  <a className="curser_point me-3">
                    <JsonDownload
                      fileName="clients-excel"
                      downloadfile={downloadData}
                    />
                  </a>
                  <a className="curser_point upload_icon">
                    {!loader && (
                      <input
                        type="file"
                        id="upLoader"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        name="images"
                        ref={fileInputRef}
                        onChange={onFileUpload}
                      />
                    )}
                    {/* <HiDownload className="uploadIcon" /> */}
                  </a>
                </div>
                <div className="col-md-4"></div>
                <div className="searchForm careSearch col-md-4">
                  <input
                    type="text"
                    className="form-control"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    placeholder="Search"
                  />
                  <BsSearch className="icon me-2" />
                </div>
                <div className="d-flex col-md-6 action_mt justify-content-end">
                  <div className="col-md-6 d-flex justify-content-start heightSetCustom">
                    <div className="date_filters">
                      <ul className="filter_box me-2 mb-1">
                        {/* <NavLink
                              exact
                              activeClassName={filterType === "today" ? "active" : ""}
                              to="#"
                              onClick={() => setFilterType("today")}
                            >
                              Today
                            </NavLink> */}
                        <NavLink
                          activeClassName={
                            filterType === "week" ? "active " : ""
                          }
                          to="#"
                          onClick={() => setFilterType("week")}
                        >
                          This Week
                        </NavLink>
                        <NavLink
                          activeClassName={
                            filterType === "custom" ? "active" : ""
                          }
                          to="#"
                          onClick={() => setFilterType("custom")}
                        >
                          Custom
                        </NavLink>
                      </ul>
                    </div>
                    {filterType === "custom" ? (
                      <div className="col-md-3 mb-2 datepicker_input">
                        <DatePicker
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(update) => {
                            customDateChangeHandler(update);
                          }}
                          isClearable={true}
                        />
                      </div>
                    ) : (
                      <div className="col-md-3 mb-2">&ensp;</div>
                    )}
                  </div>
                  <div className="col-md-5 col-xl-3 btns_head">
                    <DropdownButton id="dropdown-basic-button" title="Actions">
                      <Dropdown.Item
                        // className="btn btn-theme btn-sm"
                        disabled={currentModuleAccess?.[0]?.access !== "full"}
                        onClick={() => {
                          setIsOpen(true);
                        }}
                      >
                        Add Client
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          isPlanBase
                            ? ShowSwal(
                                false,
                                `This feature is not available in the ${currentPlanName} Plan`
                              )
                            : setOpenGroupModal(true)
                        }
                        disabled={currentModuleAccess?.[1]?.access !== "full"}
                      >
                        Create Groups
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleEditGroupClick()}
                        disabled={currentModuleAccess?.[1]?.access !== "full"}
                      >
                        Edit Group
                      </Dropdown.Item>

                      <Dropdown.Item
                        disabled={currentModuleAccess?.[0]?.access !== "full"}
                        onClick={() => {
                          if (selectedRows?.length >= 1)
                            handleShowDeletePopup(true);
                          else
                            swal(
                              "Failed",
                              "Please Select at least one client",
                              "error"
                            ).then((value) => {
                              return;
                            });
                        }}
                      >
                        Delete{" "}
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={currentModuleAccess?.[0]?.access !== "full"}
                        onClick={() => {
                          if (selectedRows?.length > 0)
                            handleShowStatusPopup(true);
                          else
                            swal(
                              "Failed",
                              "Please Select at least one client",
                              "error"
                            ).then((value) => {
                              return;
                            });
                        }}
                      >
                        Change Status{" "}
                      </Dropdown.Item>

                      <div className="position-relative">
                        <Dropdown.Item
                          onClick={openFileInput}
                          disabled={currentModuleAccess?.[0]?.access !== "full"}
                        >
                          Import Clients
                        </Dropdown.Item>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-info">
                              Download sample file (Please enter details in
                              small caps)
                            </Tooltip>
                          }
                        >
                          <span
                            style={{
                              cursor: "pointer",
                              cursor: "pointer",
                              position: "absolute",
                              right: "18px",
                              top: "49%",
                              transform: "translate(0%, -52%)",
                            }}
                            onClick={handleDownloadClick}
                          >
                            <IoMdInformationCircle color="#9e61e7" />
                          </span>
                        </OverlayTrigger>
                      </div>
                    </DropdownButton>
                  </div>
                </div>
              </div>
              <div style={{ flexGrow: 1 }}>
                <ClientNewTable
                  searchText={searchText}
                  rows={rowData}
                  selectedRows={selectedRows}
                  handleRowSelection={handleRowSelection}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal show={openGroupModal} onHide={handleGpModalClose}>
          <Modal.Body>
            <div
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      {editGroup ? "Edit" : "Create"} Group
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleGpModalClose}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body" style={{ minHeight: "400px" }}>
                    {careSiteData?.carefacility === "facalities" ? (
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Group Name</label>
                          <input
                            type="text"
                            value={groupName}
                            onChange={(e) => {
                              setgroupErr("");
                              setgroupName(e.target.value);
                            }}
                            className="form-control"
                          />
                          {groupErr.includes("groupName") && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Member Name</label>
                          {/* <ul className="selected_items">
                          {selectedname.map((item, index) => {
                            return (<>
                              <li>{`${item}`}<span className="close_icon">✖</span></li>
                            </>)
                          })}
                        </ul> */}
                          <div className="position-relative">
                            <div
                              className="hint_box"
                              style={{ display: "block" }}
                            >
                              <Select
                                options={filteredOptions}
                                value={clientsForGrp}
                                isMulti
                                onChange={handleChange}
                              />
                              {groupErr.includes("selectClient") && (
                                <p className="error_style">
                                  Select atleast one client
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p>Client Group is only available for Care Facility</p>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-success"
                      // data-bs-dismiss="modal"
                      onClick={createclientGroup}
                      disabled={careSiteData?.carefacility !== "facalities"}
                    >
                      {editGroup ? "Save" : "Create"} Group
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={isOpen}
          onHide={() => {
            setIsOpen(false);
          }}
        >
          <Modal.Body>
            <div
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-scrollable">
                <form onSubmit={handleSubmit(add_new_resident)}>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Add New Client
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                          reset();
                          resetForm();
                        }}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">First Name</label>
                          <input
                            // value={firstName}
                            // onChange={(e) => setFirstName(e.target.value)}
                            type="text"
                            className="form-control"
                            {...register("firstName", {
                              required: true,
                              pattern: /^[A-Za-z ]+$/i,
                              min: 1,
                            })}
                          />
                          {errors?.firstName?.type === "pattern" && (
                            <p className="error_style">Alphabet only</p>
                          )}
                          {errors?.firstName?.type === "required" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                          {errors?.firstName?.type === "min" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Last Name</label>
                          <input
                            // value={lastName}
                            // onChange={(e) => setlastName(e.target.value)}
                            type="text"
                            className="form-control"
                            {...register("lastName", {
                              required: true,
                              pattern: /^[A-Za-z ]+$/i,
                              min: 1,
                            })}
                          />
                          {errors?.lastName?.type === "pattern" && (
                            <p className="error_style">Alphabet only</p>
                          )}
                          {errors?.lastName?.type === "required" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                          {errors?.lastName?.type === "min" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            ContractStartDate
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            {...register("ContractStartDate", {
                              required: true,
                            })}
                          />

                          {errors?.ContractStartDate?.type === "required" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Date of Birth</label>
                          <input
                            type="date"
                            className="form-control"
                            {...register("DOB", {
                              required: true,
                            })}
                          />
                          {errors?.DOB?.type === "required" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                        {/* {currentCareSiteData?.carefacility != "agency" && <div className="col-md-12 mb-3">
                          <label className="form-label">Room No.</label>
                          <input
                            // value={lastName}
                            // onChange={(e) => setlastName(e.target.value)}
                            type="number"
                            className="form-control"
                            {...register("roomNo", {
                              required: true,
                              min: 1,
                            })}
                          />
                        </div>} */}
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Location</label>
                          <AwsLocation
                            awsLocationHandler={awsLocationHandler}
                            defaultVal={location?.Label ?? ""}
                          />
                          {locErrorMsg === "location" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-md-12">
                          <label className="form-label me-4">
                            Permanent Address
                          </label>
                          <input
                            style={{ width: "20px" }}
                            type="checkbox"
                            checked={isPermanent}
                            onChange={(e) => {
                              setIsPermanent(e.target.checked);
                            }}
                          />
                          <label className="form-label">Same as location</label>
                        </div>
                        {!isPermanent && (
                          <div className="col-md-12 mb-3 position-relative">
                            <AwsLocation
                              awsLocationHandler={permanentLocHandler}
                            />
                            {locErrorMsg === "permanent" && (
                              <p className="error_style">
                                This field is required
                              </p>
                            )}
                          </div>
                        )}

                        <div className="col-md-12 mb-3">
                          <label className="form-label">Image</label>
                          <input
                            type="file"
                            accept="image/*"
                            className="form-control"
                            {...register("uploadFile")}
                          />

                          {errors?.uploadFile?.type === "required" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                        {/* <div className="col-md-12 mb-3">
									<label className="form-label">Contact Number</label>
									<input value={ContactNumber} onChange={(e) => setContactNumber(e.target.value)} type="number" className="form-control" />
								</div>
								<div className="col-md-12 mb-3">
									<label className="form-label">Email</label>
									<input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" />
								</div>
								<div className="col-md-12 mb-3">
									<label className="form-label">Marital Status</label>
									<input value={maritalStatus} onChange={(e) => setMaritalStatus(e.target.value)} type="text" className="form-control" />
								</div>
								<div className="col-md-12 mb-3">
									<label className="form-label">Religion</label>
									<input value={religion} onChange={(e) => setReligion(e.target.value)} type="text" className="form-control" />
								</div>
								<div className="col-md-12 mb-3">
									<label className="form-label">Citizenship</label>
									<input value={citizenship} onChange={(e) => setCitizenship(e.target.value)} type="text" className="form-control" />
								</div>
								<div className="col-md-12 mb-3">
									<label className="form-label">Languages</label>
									<input value={Languages} onChange={(e) => setLanguages(e.target.value)} type="text" className="form-control" />
							</div> */}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-success">
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          className="viewModal"
          show={isDeleteOpen}
          onHide={handleCloseDeletePopup}
        >
          <Modal.Header>
            <Modal.Title>
              <span>Delete Client</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <p>
                    Are you sure you want to delete this{" "}
                    {selectedRows?.length > 1 ? "clients" : "client"} ?
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <button
                className="btn submitBtn"
                onClick={handleCloseDeletePopup}
              >
                No
              </button>
              <button
                className="btn submitBtn"
                onClick={() => {
                  deleteClients();
                }}
              >
                Yes
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          className="viewModal"
          show={isStatusOpen}
          onHide={handleCloseStatusPopup}
        >
          <form onSubmit={handleSubmitS(changeClienStatus)}>
            <Modal.Header>
              <Modal.Title>
                <span>
                  Change {selectedRows?.length > 1 ? "Clients" : "Client"}{" "}
                  Status
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label className="form-label">Type</label>
                  {/* React Select Along with React Hook Form */}
                  <Controller
                    control={control}
                    name="clientStatus"
                    rules={{ required: "status is required" }}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          isClearable
                          isSearchable={false}
                          className="react-dropdown"
                          classNamePrefix="dropdown"
                          options={ClientStatus}
                          // onChange={handleStatusChange}
                        />
                      );
                    }}
                  />
                  <p className="error_style">
                    {errorsS.clientStatus?.message ||
                      errors.clientStatus?.label.message}
                  </p>
                </div>
                {clientStatus === "moving_out" && (
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Date</label>
                    <input
                      type="date"
                      className="form-control"
                      {...registerS("dateOfMovingOut", {
                        required: clientStatus === "moving_out",
                      })}
                      name="dateOfMovingOut"
                    />
                    <p className="error_style">
                      {errorsS.dateOfMovingOut?.message ||
                        errorsS.dateOfMovingOut?.label.message}
                    </p>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <button
                  type="button"
                  className="btn submitBtn"
                  onClick={handleCloseStatusPopup}
                >
                  Close
                </button>
                <button type="submit" className="btn submitBtn">
                  Update
                </button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </>
  );
}

export default Clients;
