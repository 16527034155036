import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
import "../../style.css";
import "./header.css";
import { GiHamburgerMenu } from "react-icons/gi";
import swal from "sweetalert";
import { logoutAdmin } from "../../utils/logoutadmin";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  captureSwitchCareSiteId,
  currentCareSiteIdHandler,
  selectCareSite,
  switchCareSiteApi,
  updateCurrentCareSite,
} from "../../redux-toolkit/reducer/switchSites";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { securityQuestions } from "../../constants/constants";
import {
  authProcess,
  authenticateOwnerSignup,
  getOwnerId,
  loginOwnerId,
  getOwnerIdAfterLogin,
  securitysuccessfullMsg,
  handleEmailVerifyFromLogin,
} from "../../redux-toolkit/reducer/accountCreationApiReducer";
import { AiFillPlusCircle } from "react-icons/ai";
import { httpAdmin } from "../../Apis/commonApis";
import CircularProgress from "@mui/material/CircularProgress";
import { RefreshPage } from "../../utils/utilsHandlerFunctions";
import { compressImage } from "../../utils/CommonFunctions";
import { useForm } from "react-hook-form";
import AwsLocation from "../../utils/AwsLocation";
import { ShowSwal } from "../../utils/alertSwal";
/* global ZohoHCAsap */
const chatUserImg = '/images/chatUser.svg'

function Header() {

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const history = useHistory();
  const dispatch = useDispatch();
  // 9957192058, 9772459913, 53674848494980@yopmail.com

  const { selectedCareSiteName, selectedCareSiteId, adminUserData } = useSelector((state) => state.switchSites);
  let { careSitesData, currentPlanName, accessRoles, userDataLatest } = useSelector((state) => state.caresiteDataReducer);
  const moduleAccess = useMemo(() => {
    const findCurrentModule = accessRoles?.find(item => item.moduleName === 'MultiSite Dashboard');
    console.log(findCurrentModule, 'findCurrentModule');
    return (findCurrentModule?.access === 'full' || findCurrentModule?.access === 'view');
  }, [accessRoles]);

  let userData = JSON.parse(localStorage.getItem("userData"));

  const [createnewsitevisible, setcreatenewsitevisible] = useState(false);
  const [userDataState, setUserDataState] = useState(null);
  const [imgLoader, setImgLoader] = useState(false);

  useEffect(() => {
    setUserDataState(userData);
    if (userData) {
      dispatch(handleEmailVerifyFromLogin({ verifySuccessLogin: 0 }));
    }
  }, []);

  useEffect(() => {
    console.log('Script executed in MyComponent');
    const script = document.createElement('script');
    script.src = "https://desk.zoho.eu/portal/api/web/inapp/137666000000393001?orgId=20087490876";
    script.async = true;
    script.nonce = "{place_your_nonce_value_here}";
    script.type = "text/javascript";
    script.defer = true;
    document.body.appendChild(script);

    window.ZohoHCAsapSettings = {
      hideLauncherIcon: true
    };

    window.addEventListener("productfruits_button_ext_widget_init", () => {
      ZohoHCAsap?.Action("open");
    });

    window.onclick = function (event) {
      if (window.productFruits?.api?.button) {
        window.productFruits.api.button.showButton();
      }
    };

    // Assign a function to ZohoHCAsap.Action("open")
    window.ZohoHCAsap?.Action("open", () => {
      window.productFruits.api.button.hideButton();
    });
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleNextone = (formValues) => {
    if (location === '' && formValues.carefacility === "facalities") {
      setlocErrorMsg('location');
      return;
    }
    let careSiteData = { ...formValues };
    careSiteData.adminId = userData?._id;
    if (formValues.carefacility === "facalities") {
      careSiteData.careSiteAddress = location?.Label;
      careSiteData.lattitude = location?.latitude;
      careSiteData.longitude = location?.longitude;
    }
    careSiteData.companyName = userDataLatest?.companyName;
    console.log(careSiteData, 'formValues');
    localStorage.setItem("createNewCareSiteData", JSON.stringify(careSiteData));
    history.push(`/admin/purchagePlan`, {
      ownerId: userData._id,
      updatePlan: false,
      newCareSite: true,
    });
  };

  function verify() {
    getOwnerId({
      loginOwnerId: "",
      loginVerifyEmail: 0,
      authProcess: "",
    });
    dispatch(handleEmailVerifyFromLogin({ verifySuccessLogin: 0 }));
    logoutAdmin();
    // localStorage.removeItem("care_admin_token");
    // localStorage.removeItem("userData");
    // localStorage.removeItem("currentCareSiteId");
    history.replace("/login");
    // window.location.reload();
  }

  const [show, setShow] = useState(false);
  const [popupStatus, setPopupStatus] = useState("");

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (text) => {
    setShow(true);
    setPopupStatus(text);
  };

  const [question, setQuestion] = useState("");
  const [securityAnswer, setsecurityAnswer] = useState("");
  const { captureBeforeSwitchCareSiteId } = useSelector(
    (state) => state.switchSites
  );
  const { loginVerifyEmail } = useSelector(
    (state) => state.accountCreationApiReducer
  );

  function selectQuestion(event) {
    setQuestion(event.label);
  }

  useEffect(async () => {
    let intervalID = setInterval(async () => {
      if (loginOwnerId && loginVerifyEmail == 1) {
        await dispatch(
          authenticateOwnerSignup({ loginOwnerId: loginOwnerId, authProcess })
        );
      }
    }, 3000);
    if (loginVerifyEmail == 2) {
      swal("Success", securitysuccessfullMsg, "success").then(async (value) => {
        await dispatch(selectCareSite({ id: captureBeforeSwitchCareSiteId }));
        await dispatch(
          currentCareSiteIdHandler({
            currentCareSiteId: captureBeforeSwitchCareSiteId,
          })
        );
        currentSite(userData?._id, captureBeforeSwitchCareSiteId);
        RefreshPage();
      });
      await dispatch(
        getOwnerIdAfterLogin({
          loginOwnerId: "",
          loginVerifyEmail: 0,
          authProcess: "",
        })
      );
      return () => clearInterval(intervalID);
    }
    return () => clearInterval(intervalID);
  }, [loginVerifyEmail]);

  // -------------------------  Reset Password -------------------------  //
  async function currentSite(userId, careSiteId) {
    await dispatch(
      updateCurrentCareSite({
        userId: userId,
        careSiteId: careSiteId,
      })
    );
  }

  function checkSecurityQuestions() {
    let data = JSON.stringify({
      userId: userData?._id,
      panel: "admin",
      question: question,
      answer: securityAnswer,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/checkSecurityQuestions`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === true) {
          setQuestion("");
          swal("Success", "User successfully authenticated", "success").then(
            (value) => {
              dispatch(selectCareSite({ id: captureBeforeSwitchCareSiteId }));
              dispatch(
                currentCareSiteIdHandler({
                  currentCareSiteId: captureBeforeSwitchCareSiteId,
                  status: "login",
                  loginData: response.data.data,
                })
              );

              currentSite(userData?._id, captureBeforeSwitchCareSiteId);
              response.data.data.currentCareSiteId =
                captureBeforeSwitchCareSiteId;
              localStorage.setItem("care_admin_token", response.data.token);
              localStorage.setItem(
                "userData",
                JSON.stringify(response.data.data)
              );
              handleClose();
              RefreshPage();
            }
          );
        } else {
          swal("failed", response.data.message, "error").then((value) => {
            return;
          });
        }
      })
      .catch(function (error) {
        console.log(error, "error");
      });
  }

  const handleSelectChange = async (event) => {
    const subscriptionData = careSitesData?.find(item => item._id === event.target.value);
    if (subscriptionData?.subscriptionStatus == 'true') {
      dispatch(captureSwitchCareSiteId({ id: event.target.value }));
      try {
        const response = await dispatch(
          switchCareSiteApi({
            userId: userData?._id,
            careSiteId: event.target.value,
          })
        );
        if (response.payload.status === true) {
          if (response.payload.message == "Security question enabled") {
            handleShow("reset_securityQuestion");
            return;
          }
          if (response.payload.message.includes("No auth")) {
            swal(
              "Success",
              "You have successfully switched to other site",
              "success"
            ).then((value) => {
              dispatch(selectCareSite({ id: event.target.value }));
              dispatch(
                currentCareSiteIdHandler({
                  currentCareSiteId: event.target.value,
                })
              );
              currentSite(userData?._id, event.target.value);
              RefreshPage();
              return;
            });
          }
          if (
            response.payload.message ==
            "An authentication email has been sent to your email, Please authenticate yourself"
          ) {
            swal("Success", response.payload.message, "success").then((value) => {
              dispatch(
                getOwnerId({
                  loginOwnerId: userData?._id,
                  loginVerifyEmail: 1,
                  authProcess: "login",
                })
              );
            });
          }
        }
      } catch (e) {
        console.log(e, "Error messssages");
      }
    } else {
      ShowSwal(false, 'This site is blocked. Kindly clear outstanding payments to activate it again.')
    }
  };

  async function onFileChange(e) {
    let profileImg = e.target.files;
    if (profileImg) {
      setImgLoader(true);
      let compressedImg = await compressImage(profileImg);
      editImage(compressedImg);
    }
  }

  const editImage = async (imgg) => {
    let body = new FormData();
    body.append("image", imgg);
    const res = await httpAdmin(`changeProfilePhoto?adminId=${userData._id}`, "post", body, "formdata");
    if (res.status) {
      localStorage.setItem("userData", JSON.stringify(res.result));
      setUserDataState(res.result);
      setImgLoader(false);
    }
  };

  const [location, setlocation] = useState("");
  const [locErrorMsg, setlocErrorMsg] = useState('');
  function awsLocationHandler(locVal) {
    setlocation(locVal);
    if (locVal) setlocErrorMsg('');
  }

  let locationPlaceholder = 'CareSite Location (where care services will be given)';

  return (
    <>

      <header className="topbar">
        <nav className="navbar top-navbar navbar-expand-md navbar-dark">
          {/* <ProductFruits workspaceCode="UHf8gK434TsyGDjw" language="en-US" {...userInfo} /> */}
          <div className="navbar-header">
            <button className="nav-toggler waves-effect waves-light d-block d-md-none hemburger_menu">
              <GiHamburgerMenu />
            </button>
            <NavLink className="navbar-brand me-0" to="/">
            <b className="logo-icon">
              <img
                style={{ width: "110px", transition: "none" }}
                src={`/images/logo2.svg`}
              />
            </b>
            </NavLink>
            <NavLink
              className="topbartoggler d-block d-md-none waves-effect waves-light"
              to=""
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="ti-more"></i>
            </NavLink>
          </div>
          <div className="navbar-collapse collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto"></ul>
            <ul className="navbar-nav">
              <li className="nav-item dropdown create_list d-flex">
                {/* <h5>Hello! {`${userData?.firstName}`}</h5> */}

                <NavLink
                  className="nav-link dropdown-toggle waves-effect waves-dark"
                  to="#"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {selectedCareSiteName}

                  {/* {JSON.stringify(data[0].careSiteName)} */}
                </NavLink>
                <div className="dropdown-menu dropdown-menu-end user-dd animated flipInY">
                  {(userData?.userType === 'owner' && currentPlanName === 'Turbo') && <button
                    className="popupBtn popupBtn1"
                    onClick={() => {
                      setcreatenewsitevisible(true);
                      reset();
                    }}
                  >
                    Create New Site
                  </button>}
                  {(currentPlanName === 'Turbo') && <button
                    disabled={moduleAccess ? false : true}
                    style={{ color: moduleAccess ? 'var(--main)' : '' }}
                    className="popupBtn"
                    onClick={() => { history.push('/admin/multiSites/operations') }}
                  >
                    MultiSite Dashboard
                  </button>}
                  {(careSitesData && careSitesData.length > 0) ? (
                    <select
                      defaultValue={selectedCareSiteId}
                      onChange={handleSelectChange}
                    >
                      {careSitesData?.map((item) => {
                        return (
                          <option
                            key={item._id}
                            value={item._id}
                            selected={
                              item?._id === selectedCareSiteId ? true : false
                            }
                          >
                            {item.careSiteName}
                          </option>
                        );
                      })}
                    </select>
                  ) :
                    <button className="popupBtn">
                      Not Available
                    </button>}
                </div>
              </li>

              <li className="nav-item dropdown profile">
                <NavLink
                  className="nav-link dropdown-toggle waves-effect waves-dark"
                  to="#"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    className={`profile-pic rounded-circle ${userDataState?.image.includes('chatUser') ? 'forImageadmin' : 'table_image'}`}
                    src={
                      userDataState?.image
                        ? userDataState?.image
                        : chatUserImg
                    }
                    alt="user"
                    width="40"
                  />
                </NavLink>
                <div className="dropdown-menu dropdown-menu-end user-dd animated flipInY">
                  <div
                    className="no-block align-items-center p-3 text-white mb-2 text-center"
                    style={{ minWidth: "270px" }}
                  >
                    <div className="">
                      {imgLoader ? (
                        <div>
                          <CircularProgress color="secondary" />
                        </div>
                      ) : (
                        <img
                          className={`profile_img rounded-circle ${userDataState?.image.includes('chatUser') ? 'forImageadmin' : ''}`}
                          src={
                            userDataState?.image
                              ? userDataState?.image
                              : chatUserImg
                          }
                          alt="user"
                          width="60"
                        />
                      )}
                      <div className="editProfile_imgicon">
                        <input
                          type="file"
                          id="upLoader"
                          accept="image/*"
                          name="images"
                          onChange={onFileChange}
                        />
                        <span>
                          <AiFillPlusCircle className="edit_icon" />
                        </span>
                      </div>
                    </div>
                    <div className="ms-2">
                      <h4
                        className="mb-0"
                        style={{ color: "#828282" }}
                      >{`${userData?.firstName} ${userData?.lastName}`}</h4>
                      <p className=" mb-0" style={{ color: "#828282" }}>
                        {userData?.email}
                      </p>
                    </div>
                  </div>
                  <NavLink
                    className="dropdown-item"
                    to="/admin/profile/details"
                    style={{ color: "#828282" }}
                  >
                    Account Hub
                  </NavLink>
                  <NavLink
                    className="dropdown-item"
                    to="/admin/event-logs"
                    style={{ color: "#828282" }}
                  >
                    Event Logs
                  </NavLink>
                  <NavLink
                    className="dropdown-item"
                    to="/admin/settings"
                    style={{ color: "#828282" }}
                  >
                    Settings
                  </NavLink>
                  <p
                    className="dropdown-item"
                    style={{ color: "#828282" }}
                    onClick={() => verify()}
                  >
                    Logout
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <div
        className="modal modal_ak"
        id="addNewPopup"
        style={{ display: `${createnewsitevisible ? "block" : "none"}` }}
      >
        <div
          className="bg_popup"
          onClick={() => {
            setcreatenewsitevisible(false);
          }}
        ></div>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 titleN">
                  <h3>New Site Creation</h3>
                  <button
                    type="button"
                    className="btn-close close_popup_btn"
                    onClick={() => {
                      setcreatenewsitevisible(false);
                    }}
                  ></button>
                </div>
              </div>

              <div
                className="step1"
                id="step1"
              >
                <div className="row text-center">
                  {/* <div className="col-md-6 siteCreation">
                    <h2>Step 1 <BsArrowRightShort /> </h2>
                    <h4>Care Site Details</h4>
                  </div> */}
                  <div className="col-2"></div>
                  <div className="col-md-8 contentCreate">
                    <form>
                      <h4 className="mb-4">Care Site Details</h4>
                      <div className="col-md-12 contentCreate">
                        {/* <input
                          type="text"
                          id="companyName"
                          className="form-control"
                          {...register("companyName", {
                            required: true,
                          })}
                          placeholder="company Name"
                        />
                        {errors?.companyName?.type === "required" && (
                          <p className="error_style error_bottom pb-2">This field is required</p>
                        )} */}
                        <input
                          type="text"
                          id="careSiteName"
                          className="form-control"
                          {...register("careSiteName", {
                            required: true,
                          })}
                          placeholder="careSite Name"
                        />
                        {errors?.careSiteName?.type === "required" && (
                          <p className="error_style error_bottom pb-2">This field is required</p>
                        )}
                        <select
                          className="form-control form-select"
                          {...register("carefacility", {
                            required: true,
                          })}
                        >
                          <option value="agency">Care Agency</option>
                          <option value="facalities">Care Facilities </option>
                        </select>
                        {watch('carefacility', '') === "facalities" &&
                          <AwsLocation awsLocationHandler={awsLocationHandler} placeholder={locationPlaceholder} />
                        }
                        {(locErrorMsg === 'location') && (
                          <p className="error_style error_bottom">
                            This field is required
                          </p>
                        )}
                        {watch('carefacility', '') === "facalities" && <input
                          type="number"
                          id="totalBeds"
                          placeholder="No. of Beds (if site is a care facility)"
                          className="form-control"
                          {...register("totalBeds", {
                            required: true,
                          })}
                        />}
                        {errors?.totalBeds?.type === "required" && (
                          <p className="error_style error_bottom pb-2">This field is required</p>
                        )}
                      </div>
                    </form>{" "}
                  </div>
                </div>
              </div>

              <div className="col-md-12 submitBtn">
                <button
                  className="btn"
                  onClick={handleSubmit(handleNextone)}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {popupStatus === "reset_securityQuestion" && (
        <Modal className="viewModal" show={show}>
          <Modal.Header>
            <Modal.Title>
              <span>Enter Security Question</span>
              {/* <button
                className="popupCloseIcon"
                title="Close"
                onClick={handleClose}
              >
                <MdClose />
              </button> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <h4>Security Questions</h4>
              <Select
                options={securityQuestions}
                onChange={selectQuestion}
                required={true}
              />
              {question !== "" && (
                <input
                  style={{ marginTop: "20px" }}
                  type="text"
                  id="securityAnswer"
                  className={'form-control mt-2 '}
                  value={securityAnswer}
                  onChange={(e) => setsecurityAnswer(e.target.value)}
                  placeholder="Security Answer"
                />
              )}{" "}

              <button
                type="button"
                onClick={checkSecurityQuestions}
                className="form-control btn"
              >
                Authenticate{" "}
              </button>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
export default Header;
