import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpAdmin } from "../../Apis/commonApis";
import { currentCareSiteId } from "./switchSites";

const initialState = {
    loading: false,
    eventLogs: [],
    status: false,
};

export const fetchEventLogs = createAsyncThunk(
    "fetchEventLogs",
    async (query) => {
        const result = await httpAdmin(
            `getEventLog${query}`,
            "get",
            null,
            null
        );
        return result;
    }
);

export const createAccessEvent = createAsyncThunk(
    "createAccessEvent",
    async (eventData) => {
        const careHomeId = localStorage.getItem("carehomeId");
        const userData = JSON.parse(localStorage.getItem("userData"));
        const data = {
            changeType: "Read",
            endpoint: window.location?.pathname,
            careHomeId: currentCareSiteId ?? careHomeId,
            reasonForAttestation: "-",
            // documentId: "673c2f7aacecdf82092151cb",
            userId: userData?._id,
            ...eventData
        };
        const result = await httpAdmin(`createEventLog`, "post", data, null);
        return result;
    }
);

const eventLogsSlice = createSlice({
    name: "eventLogsSlice",
    initialState,
    reducers: {
        resetInitialState(state = initialState, action) { },
    },
    extraReducers: {
        [fetchEventLogs.pending]: (state, { payload }) => {
            state.loading = true;
        },
        [fetchEventLogs.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.eventLogs = payload.result ?? [];
        },
        [createAccessEvent.fulfilled]: (state, { payload }) => {
            state.status = true;
        },
    },
});

export const { resetInitialState } = eventLogsSlice.actions;
export default eventLogsSlice.reducer;
