import React, { useEffect, useMemo, useRef } from "react";
import "../Clients/clients.css";
import { useState, useContext } from "react";
import axios from "axios";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import Chart from "./chart";
import { CareTeamOptions, ClientOptions } from "../../constants/constants";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GoDownload } from "react-icons/go";
import { HiDownload } from "react-icons/hi";
import { AiFillPrinter } from "react-icons/ai";
import { httpAdmin } from "../../Apis/commonApis";
import html2canvas from 'html2canvas';
import { formatDate, getFullName } from "../../utils/CommonFunctions";
import { useDispatch, useSelector } from "react-redux";
import JsonDownload from "../../components/DownloadExcel/DownloadExcel";
import { convertInMile } from "../../utils/utilsHandlerFunctions";
import { createAccessEvent } from "../../redux-toolkit/reducer/eventLogsReducer";

const type = [
  {
    value: 'client',
    label: "Clients",
  },
  {
    value: 'careteam',
    label: "Care Team Member",
  },
];

let monthAgoDate = new Date();
let monthAgo = monthAgoDate.getMonth() - 1;
monthAgoDate.setMonth(monthAgo);

let helperArrDate = [];
let customDates = [];

function Report() {

  let { currentCareSiteData } = useSelector((state) => state.caresiteDataReducer);

  const [clientsList, setClientsList] = useState([]);
  const [careTeamList, setCareTeamList] = useState([]);
  const [searchType, setSearchType] = useState(type[0]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchForData, setSearchForData] = useState([]);
  const [error, setError] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);
  const [measureUnit, setMeasureUnit] = useState('');

  const [dateRange, setDateRange] = useState([monthAgoDate, new Date()]);
  const [startDate, endDate] = dateRange;

  const dispatch = useDispatch();
  useEffect(() => {
    // create access event log
    const logData = {
      documentType: `report`,
      description: `Report page visited`,
      // documentId: careteamId,
    };
    dispatch(createAccessEvent(logData));
  }, []);

  useEffect(() => {
    getClientRowData();
    getCareTeamRowData();
  }, []);

  // useEffect(() => {
  //   if (endDate) {
  //     cutomizeHz();
  //   }
  // }, [dateRange, searchType]);

  useEffect(() => {
    if (selectedCategory !== '' && searchForData.length > 0 && endDate) {
      cutomizeHz();
      const valuesArray = searchForData.map(item => item.value);
      getReportData(searchType.value, selectedCategory['value'], selectedCategory['subheading'], formatDate(startDate), formatDate(endDate), valuesArray);
    }
  }, [dateRange, selectedCategory, searchForData]);

  const getReportData = async (cat, subcat, subhead, start, end, id) => {
    let body = {
      careSiteId: currentCareSiteId,
      category: cat,
      subCategory: subhead === 'wellbeing' ? 'wellbeing' : subcat,
      startDate: start,
      endDate: end,
      ids: id,
    }
    const result = await httpAdmin(`getReports?category`, 'post', body, null);
    if (result.status) {
      let unitOfMeasure = '';
      let cdata = [];
      let datakey = [];
      result.data?.forEach((item, i) => {
        let name = getFullName(item);
        //  item.first_Name + ' ' + item.last_Name;
        datakey.push(name);
      });
      setDataKeys(datakey);
      customDates.forEach((elem, index) => {
        cdata.push({ name: elem });
      });
      if (cat === 'careteam') {
        if (subcat === 'weeklyHoursAssigned' || subcat === 'weeklyHoursWorked') {
          unitOfMeasure = 'Hrs';
          cdata.forEach((elem, index) => {
            result.data?.forEach((item, i) => {
              let name = getFullName(item);
              //  `${item.first_Name} ${item.last_Name}`;
              const arrayOfObjects = item.dashboardData[0] && Object?.entries(item.dashboardData[0])?.map(([key, value]) => ({ date: key, ...value }));
              let filteredValues = arrayOfObjects?.filter(filterWeeklyHrs(helperArrDate[0], helperArrDate[index]));
              const allSubObjects = filteredValues?.map(item => {
                const subObjects = Object?.values(item).filter(value => typeof value === 'object');
                return subObjects;
              });
              const flattenedArray = allSubObjects ? [].concat(...allSubObjects) : [];
              // console.log(allSubObjects, 'flattenedArray');
              let totalHours = 0;
              if (subcat === 'weeklyHoursAssigned') {
                totalHours = flattenedArray?.reduce((total, item) => {
                  const start = new Date(item.shiftStart);
                  const end = new Date(item.shiftEnd);
                  const diffInMilliseconds = end - start;
                  const hours = diffInMilliseconds / (1000 * 60 * 60);
                  return total + hours;
                }, 0);
              } else {
                totalHours = flattenedArray.reduce((acc, obj) => +acc + +obj.regularHours, 0);
              }
              // console.log(totalHours, 'totalHours')
              if (item.dashboardData[0] && Object?.keys(item.dashboardData[0]).length > 0) {
                elem[name] = totalHours ? limitToTwoDecimal(totalHours) : 0;
              } else {
                elem[name] = 0;
              }
            });
          });
        } else if (subcat === 'performance') {
          unitOfMeasure = '%';
          cdata.forEach((elem, index) => {
            result.data?.forEach((item, i) => {
              let name = getFullName(item);
              //  item.first_Name + ' ' + item.last_Name;
              let filteredValues = item.performance?.filter(filterByDate(helperArrDate[index]));
              let totalCount = filteredValues.reduce((acc, obj) => acc + obj.score, 0);
              if (totalCount) totalCount = Math.floor(totalCount * 100) / 100;
              // console.log(filteredValues, totalCount, 'filteredValues')
              if (item.performance.length > 0) {
                elem[name] = totalCount ? totalCount : 0;
              } else {
                elem[name] = 0;
              }
            });
          });
        } else if (subcat === 'mileage') {
          unitOfMeasure = 'mile';
          cdata.forEach((elem, index) => {
            result.data?.forEach((item, i) => {
              let name = getFullName(item);
              //  item.first_Name + ' ' + item.last_Name;
              let filteredValues = item.mileage?.filter(filterByDate(helperArrDate[index]));
              let totalCount = filteredValues.reduce((acc, obj) => acc + obj.distance, 0);
              const inMiles = totalCount * 0.000621371;
              if (totalCount) totalCount = Math.floor(inMiles * 100) / 100;
              // console.log(filteredValues, totalCount, 'filteredValues')
              if (item?.mileage?.length > 0) {
                elem[name] = totalCount ? (totalCount) : 0;
              } else {
                elem[name] = 0;
              }
            });
          });
        } else {
          let customSubCat = subcat;
          if (subcat === 'shiftPunctualityRating' || subcat === 'taskPunctualityRating' || subcat === 'fandfRating') {
            customSubCat = 'fandfRating';
          }
          cdata.forEach((elem, index) => {
            result.data?.forEach((item, i) => {
              let name = getFullName(item);
              //  item.first_Name + ' ' + item.last_Name;
              const arrayOfObjects = Object?.entries(item.dashboardData[0])?.map(([key, value]) => ({ date: key, ...value }));
              let filteredValues = arrayOfObjects?.filter(filterByDate(helperArrDate[index]));
              const totalCount = filteredValues.reduce((acc, obj) => acc + obj[customSubCat], 0);
              // console.log(filteredValues, totalCount, 'filteredValues')
              if (Object?.keys(item.dashboardData[0]).length > 0) {
                elem[name] = totalCount ? totalCount : 0;
              } else {
                elem[name] = 0;
              }
            });
          });
        }
      } else {
        if (subhead === 'admin') {
          if (subcat === 'hrsOfService') unitOfMeasure = '';
          cdata.forEach((elem, index) => {
            result.data?.forEach((item, i) => {
              let name = getFullName(item);
              //  `${item.first_Name} ${item.last_Name}`;
              const arrayOfObjects = Object?.entries(item.dashboardData[0])?.map(([key, value]) => ({ date: key, value: value }));
              let filteredValues = arrayOfObjects?.filter(filterByDate(helperArrDate[index]));
              let totalCount = filteredValues.reduce((acc, obj) => acc + obj.value, 0);
              if (Object?.keys(item.dashboardData[0]).length > 0) {
                elem[name] = totalCount ? totalCount : 0;
              } else {
                elem[name] = 0;
              }
            });
          });
        } else if (subhead === 'health') {
          if (subcat === 'BMI') {
            cdata.forEach((elem, index) => {
              result.data?.forEach((item, i) => {
                let name = getFullName(item);
                let name2 = name + '~2';
                const arrayOfObjects = item.BMI?.map((item) => ({ date: item.date, item }));
                let filteredValues = arrayOfObjects?.filter(obj => formatDate(obj.date) === helperArrDate[index]);
                const tasksArray = filteredValues?.map(data => data.item);
                let totalCount = tasksArray.reduce((acc, obj) => +acc + +obj.BMI, 0);
                let finalVal = totalCount ? (+totalCount / tasksArray.length) : 0;
                let joinedVals = tasksArray?.map(item => `${item.BMI ? item.BMI : ''}`);
                if (item.BMI.length > 0) {
                  elem[name] = limitToTwoDecimal(finalVal);
                } else {
                  elem[name] = 0;
                }
                if (joinedVals?.length > 1) {
                  elem[name2] = joinedVals.join(', ');
                } else {
                  elem[name2] = '';
                }
              });
            });
          } else if (subcat === 'BP') {
            cdata.forEach((elem, index) => {
              result.data?.forEach((item, i) => {
                unitOfMeasure = 'mmHg';
                let name = getFullName(item);
                let name2 = name + '~2';
                const arrayOfObjects = Object?.entries(item.dashboardData[0])?.map(([key, value]) => ({ date: key, ...value }));
                let filteredValues = arrayOfObjects?.filter(obj => formatDate(obj.date) === helperArrDate[index]);
                let tasksArray = [];
                if (filteredValues.length > 0) {
                  tasksArray = Object.values(filteredValues[0])?.map(vals => vals.task)?.filter(task => task?.input);
                }
                let totalCount = tasksArray?.reduce((acc, obj) => +acc + +obj?.input?.Systolic_BP?.measureValue, 0);
                let finalVal = tasksArray.length > 0 ? (+totalCount / tasksArray.length) : 0;
                let joinedVals = tasksArray?.map(task => `${task?.input?.Systolic_BP?.measureValue}/${task?.input?.Diastolic_BP?.measureValue}`);
                if (Object?.keys(item.dashboardData[0])?.length > 0) {
                  elem[name] = limitToTwoDecimal(finalVal);
                } else {
                  elem[name] = 0;
                }
                if (joinedVals?.length > 1) {
                  elem[name2] = joinedVals.join(', ');
                } else {
                  elem[name2] = '';
                }
              });
            });
          } else {
            cdata.forEach((elem, index) => {
              result.data?.forEach((item, i) => {
                let name = getFullName(item);
                let name2 = name + '~2';
                const arrayOfObjects = Object.entries(item.dashboardData[0])?.map(([key, value]) => ({ date: key, ...value }));
                let filteredValues = arrayOfObjects?.filter(obj => formatDate(obj.date) === helperArrDate[index]);
                let tasksArray = [];
                if (filteredValues.length > 0) {
                  tasksArray = Object.values(filteredValues[0])?.map(vals => vals.task)?.filter(task => task?.input);
                }
                if (tasksArray?.length > 0) unitOfMeasure = tasksArray[0]?.input?.measureType;
                let totalCount = tasksArray.reduce((acc, obj) => (+acc + +obj?.input?.measureValue), 0);
                let finalVal = tasksArray?.length > 0 ? (+totalCount / tasksArray.length) : 0;
                let joinedVals = tasksArray?.map(item => `${item.input?.measureValue}`);
                if (subcat.includes('Daily Liquid') || subcat === 'Intake') {
                  finalVal = tasksArray.reduce((acc, obj) => acc + +obj.input?.measureValue, 0);
                }
                if (Object?.keys(item.dashboardData[0])?.length > 0) {
                  elem[name] = limitToTwoDecimal(finalVal);
                } else {
                  elem[name] = 0;
                }
                if (joinedVals?.length > 1) {
                  elem[name2] = joinedVals.join(', ');
                } else {
                  elem[name2] = '';
                }
              });
            });
          }
        } else {
          cdata.forEach((elem, index) => {
            result.data?.forEach((item) => {
              let name = getFullName(item);
              let name2 = name + '~2';
              const arrayOfObjects = Object.entries(item.dashboardData[0])?.map(([key, value]) => ({ date: key, ...value }));
              let filteredValues = arrayOfObjects?.filter(filterByDate(helperArrDate[index]));
              const itemsArray = [];
              filteredValues.forEach(item => {
                for (const key in item) {
                  if (item.hasOwnProperty(key)) {
                    if (item[key].wellbeingAssessment) {
                      itemsArray.push(item[key].wellbeingAssessment);
                    }
                  }
                }
              });
              let joinedVals = itemsArray?.map(item => `${item[subcat] ? item[subcat] : ''}`);
              const max = itemsArray.reduce((maxValue, item) => {
                return Math.max(maxValue, item[subcat]);
              }, 0);
              if (Object?.keys(item.dashboardData[0]).length > 0) {
                elem[name] = max ? max : 0;
              } else {
                elem[name] = 0;
              }
              if (joinedVals?.length > 1) {
                elem[name2] = joinedVals.join(', ');
              } else {
                elem[name2] = '';
              }
            });
          });
        }
      }
      // console.log(cdata, 'cdataaaaaaaaaa');
      setMeasureUnit(unitOfMeasure);
      setChartData(cdata);
    }
  }

  function limitToTwoDecimal(number) {
    return Math.round(number * 100) / 100;
  }

  function filterWeeklyHrs(minDate, maxDate) {
    return function (item) {
      let firstDate = new Date(item.date.split(' - ')[0]).getTime();
      let secondDate = new Date(item.date.split(' - ')[1]).getTime();
      let hzDate = new Date(maxDate).getTime();
      return firstDate <= hzDate && secondDate >= hzDate;
    };
  }

  function filterByDate(maxDate) {
    return function (item) {
      let dataDate = new Date(item.date);
      let compareDate = new Date(maxDate);
      return (
        dataDate.getFullYear() === compareDate.getFullYear() &&
        dataDate.getMonth() === compareDate.getMonth() &&
        dataDate.getDate() === compareDate.getDate()
      );
    };
  }

  function cutomizeHz() {
    helperArrDate = [];
    customDates = [];
    const getDateDifference = (date1, date2) => {
      const diffTime = Math.abs(date2 - date1);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };
    let difference = getDateDifference(startDate, endDate);
    for (let i = 0; i <= difference; i++) {
      let finalDate = new Date(startDate);
      if (i > 0) {
        let dayByInterval = new Date(helperArrDate[0]).getDate() + i;
        finalDate.setDate(dayByInterval);
        if (finalDate.getTime() >= endDate.getTime()) {
          finalDate = new Date(endDate);
        }
      }
      let formatedDate = formatDate(finalDate)
      helperArrDate.push(formatedDate);
      let changedFormat = finalDate?.toLocaleDateString('en-GB')?.slice(0, 5);
      customDates.push(changedFormat);
      if (finalDate.getTime() >= endDate.getTime()) {
        break;
      }
    }
    let cdata = [];
    customDates.forEach((elem, index) => {
      cdata.push({ name: elem });
    });
    setChartData(cdata);
  }

  const getClientRowData = () => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/getClient?careHomeId=${currentCareSiteId}&type=medicine`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    }).then((res) => {
      const ClientData = res?.data?.clientListing?.clientListing;
      const data = ClientData?.map((item) => {
        return {
          value: item._id,
          label: getFullName(item),
        };
      });
      setClientsList(data);
    }).catch((error) => console.log(`Error: ${error}`));
  };

  const getCareTeamRowData = () => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/getCareteam?careHomeId=${currentCareSiteId}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    })
      .then((res) => {
        let CareTeamData = res.data.careteamListing;
        let data = CareTeamData.map((item) => {
          return {
            value: item._id,
            label: getFullName(item),
            // `${item.first_Name.trim() + " " + item.last_Name.trim()}`,
          };
        });
        setCareTeamList(data);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };

  function handleTypeSelect(e) {
    setSearchType(e);
    setSearchForData([]);
    setSelectedCategory('');
  }

  function handleCategoryChange(e) {
    setSelectedCategory(e);
  }

  function handleSearchForChange(e) {
    if (e.length > 5) {
      setError(true);
    } else {
      setError(false);
      setSearchForData(e);
    }
  }

  const chartRef = useRef(null);

  function removeKeysWithSubstring(array, substring) {
    return array.map(obj => {
      const newObj = {};
      newObj.date = obj.name;
      Object.keys(obj).forEach(key => {
        if (!key.includes(substring) && key !== 'name') {
          newObj[`${key} [${selectedCategory?.label}]`] = obj[key];
        }
      });
      return newObj;
    });
  }

  const handleDownloadClick = async () => {
    if (chartRef.current) {
      const chartNode = chartRef.current;
      const canvas = await html2canvas(chartNode);
      const dataURL = canvas.toDataURL('image/jpg');
      const downloadLink = document.createElement('a');
      downloadLink.href = dataURL;
      downloadLink.download = 'chart.jpg'; // Set the filename
      downloadLink.click(); // Simulate a click to trigger the download
      // console.log('33333333333')
    }
  };

  const modifyCareTeamOptions = useMemo(() => {
    if (currentCareSiteData?.carefacility === 'agency') {
      return CareTeamOptions;
    } else {
      return CareTeamOptions.filter(item => item.value !== 'mileage');
    }
  }, [currentCareSiteData]);

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <div className="top_menubar mb-5">
                <div className="btns_head">
                  <div className="row align-item-center">
                    <div className="col-md-1"></div>
                    <div className="col-md-3">
                      <div className="dropdown w-100 category_dropdown mb-3 category_dropdown_1">
                        <Select
                          options={type}
                          value={searchType}
                          onChange={handleTypeSelect}
                          getSearchLabel={(e) => (
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <span style={{ marginLeft: 5 }}>{e.label}</span>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="dropdown w-100 category_dropdown mb-3 category_dropdown_1">
                        <Select
                          placeholder="Select Category"
                          value={selectedCategory}
                          options={searchType?.label === "Clients"
                            ? ClientOptions
                            : modifyCareTeamOptions}
                          onChange={handleCategoryChange}
                          getOptionLabel={(e) => (
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <strong style={{ marginLeft: 5, fontWeight: 500 }}>{e.heading}</strong>
                              <span style={{ marginLeft: 5 }}>{e.label}</span>
                            </div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-md-3 datepicker_input">
                      {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          setDateRange(update);
                        }}
                        isClearable={true}
                      />
                    </div>
                    <div className="col-md-2 report_downld_btn">
                      <AiFillPrinter
                        className="me-3 curser_point"
                        onClick={() => window.print()}
                      />
                      <a className="curser_point me-3">
                        <JsonDownload
                          fileName={`${searchType?.label}_${selectedCategory?.label}`}
                          downloadfile={removeKeysWithSubstring(chartData, '~2')}
                        />
                      </a>
                      {/* <a className="curser_point"
                        onClick={handleDownloadClick}>
                        <HiDownload className="curser_point" />
                      </a> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-6">
                      <Select
                        isMulti
                        value={searchForData}
                        options={searchType?.label === "Clients"
                          ? clientsList
                          : careTeamList
                        }
                        onChange={handleSearchForChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        getSearchLabel={(e) => (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                          </div>
                        )}
                      />
                      {error && (
                        <p className="error_style">maximum 5 is allowed</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div ref={chartRef}>
                <Chart chartData={chartData} dataKeys={dataKeys} selectedCat={selectedCategory} measureUnit={measureUnit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Report;