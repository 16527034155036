import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetch3 } from "../../Apis/commonApis";
//fandfRating Star Rating

export let notesData;

const initialState = {
  loading: false,
  taskCount: 0,
  completeCount: 0,
  notesListCount: 0,
};

export const notesList = createAsyncThunk("notesList", async (body) => {
  const result = await fetch3(
    `${process.env.REACT_APP_BASEURL}/getNotes?clientId=${body.clientId}&shiftId=${body.shiftId}&careHomeId=${body.careHomeId}&careTeamId=${body.careTeamId}`,
    "get"
  );
  notesData = result.clientData;
  return result;
});

const JournalSlice = createSlice({
  name: "JournalSlice",
  initialState,
  reducers: {
    captureData(state = initialState, action) {
      const keys = Object.keys(action.payload);
      keys.forEach((item) => {
        state[item] = action.payload[item];
      });
    },
    completedTasks(state = initialState, action) {
      let tasks = [
        ...action.payload?.Task,
        action.payload?.wellbeingAssessment,
      ];
      let completed = tasks?.filter((item) => item.status == "true");
      state.taskCount = tasks?.length;
      state.completeCount = completed?.length;
    },
  },
  extraReducers: {
    [notesList.fulfilled]: (state, { payload: { error, message } }) => {
      state.loading = false;
      state.notesListCount += 1;
    },
  },
});

export const { completedTasks, captureData } = JournalSlice.actions;
export default JournalSlice.reducer;
