import React, { useEffect, useMemo, useState } from "react";
import CareMagnusTable from "../../utils/CareMagnusTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchEventLogs } from "../../redux-toolkit/reducer/eventLogsReducer";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import {
  convertToTimeZoneFullTime,
  formatDate,
  getFullName,
  getMondayDate,
  getMonthAgoDate,
  getObjectKeysInString,
  getObjectValsInString,
} from "../../utils/CommonFunctions";
import { BsSearch } from "react-icons/bs";
import { filterData } from "../../utils/TableSearchFilter";
import DatePicker from "react-datepicker";
import { Switch, Route, NavLink } from "react-router-dom";

let monthAgoDate = getMonthAgoDate();
let today = new Date();

const LogsTable = () => {
  const dispatch = useDispatch();
  const { eventLogs } = useSelector((state) => state.eventLogsReducer);

  const [selected, setSelected] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [filterType, setFilterType] = useState("week");
  const [dateRange, setDateRange] = useState([monthAgoDate, new Date()]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (filterType === "custom" && endDate) {
      getLogs(formatDate(startDate), formatDate(endDate));
    } else if (filterType === "week") {
      let mondayDate = getMondayDate();
      getLogs(formatDate(mondayDate), formatDate(today));
    } else if (filterType === "today") {
      // getLogs(today, today);
    }
  }, [filterType, dateRange]);

  const getLogs = (start, end) => {
    const queryString = `?careHomeId=${currentCareSiteId}&startDate=${start}&endDate=${end}`;
    dispatch(fetchEventLogs(queryString));
  };

  const columns = [
    {
      field: "documentId",
      headerName: "Record Id",
      flex: 1,
      minWidth: 120,
      valueFormatter: (params) => {
        return params.value ?? "-";
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      minWidth: 120,
      valueFormatter: (params) => {
        return params.value ? convertToTimeZoneFullTime(params.value) : "-";
      },
    },
    {
      field: "committer",
      headerName: "Committer",
      minWidth: 120,
    },
    { field: "changeType", headerName: "Change Type", minWidth: 90 },
    { field: "endpoint", headerName: "Path", flex: 1, minWidth: 120 },
    { field: "description", headerName: "Description", flex: 1, minWidth: 120 },
    {
      field: "changedDetails",
      headerName: "Changed Details",
      minWidth: 120,
    },
    {
      field: "change",
      headerName: "Change",
      minWidth: 120,
    },
    {
      field: "proof",
      headerName: "Proof",
      minWidth: 100,
    },
    {
      field: "reasonForAttestation",
      headerName: "Reason for Attestation",
      minWidth: 120,
    },
    // { field: "eventId", headerName: "Event ID", minWidth: 120 },
    { field: "version", headerName: "Record version", width: 120 },
    {
      field: "systemIdentifier",
      headerName: "System identifier",
      minWidth: 120,
    },
    { field: "_id", headerName: "Event Id", flex: 1, minWidth: 120 },
  ];

  const handleRowSelection = (rowId) => {
    setSelected(rowId.slice(-1));
  };

  function customDateChangeHandler(update) {
    setDateRange(update);
  }

  const eventLogRows = useMemo(() => {
    return eventLogs.map((item) => {
      const isProof =
        item.reasonForAttestation?.includes("no reason") ||
        item.reasonForAttestation === "-";
      return {
        ...item,
        changedDetails: getObjectKeysInString(item.details),
        change: getObjectValsInString(item.details),
        committer: getFullName(item.userId),
        proof: isProof ? "-" : getFullName(item.userId),
      };
    });
  }, [eventLogs]);

  const filteredData = filterData(
    columns?.map((item) => ({
      id: item.field,
      label: item.headerName,
    })),
    eventLogRows,
    searchText
  );

  return (
    <div className="page-wrapper">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <ul
                className="nav nav-tabs ract_tab_list border-0 listspaceGap"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button className="nav-link settingsColor">
                    Event Logs {">"}
                  </button>
                </li>
              </ul>
            </div>
            <div
              className="col-md-3 searchForm"
              style={{ marginLeft: "0px", transform: "translate(-6%, 0%)" }}
            >
              <input
                type="text"
                className="form-control"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                placeholder="search"
              />
              <BsSearch className="icon me-2" />
            </div>

            <div className="col-md-5">
              <div className="col-md-12 dateFilter_sales">
                <div className="">
                  <ul className="filter_box">
                    {/* <NavLink
                          exact
                          activeClassName={filterType === "today" ? "active" : ""}
                          to="#"
                          onClick={() => setFilterType("today")}
                        >
                          Today
                        </NavLink> */}
                    <NavLink
                      activeClassName={
                        filterType === "week" ? "active mx-3" : "mx-3"
                      }
                      to="#"
                      onClick={() => setFilterType("week")}
                    >
                      This Week
                    </NavLink>
                    <NavLink
                      activeClassName={filterType === "custom" ? "active" : ""}
                      to="#"
                      onClick={() => setFilterType("custom")}
                    >
                      Custom
                    </NavLink>
                  </ul>
                </div>
                {filterType === "custom" ? (
                  <div className="input_space">
                    <DatePicker
                      selectsRange={true}
                      dateFormat="dd/MM/yyyy"
                      startDate={startDate}
                      endDate={endDate}
                      maxDate={today}
                      onChange={(update) => {
                        customDateChangeHandler(update);
                      }}
                      isClearable={true}
                    />
                  </div>
                ) : (
                  <div className="input_space">&ensp;</div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-4">
            <CareMagnusTable
              tableHeight="75vh"
              idParam="_id"
              columns={columns}
              rows={filteredData}
              selectedRow={selected}
              handleRowSelection={handleRowSelection}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogsTable;
