import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import "../CareTeam/careteam.css";
import Select from "react-select";
import { NavLink } from "react-router-dom";
import CareTeamNewTable from "./CareTeamNewTable";
import datechangeHandler from "../../utils/datechangehandler";
import { useForm, Controller } from "react-hook-form";
import { Modal, Dropdown, DropdownButton, OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from "sweetalert";
import JsonDownload from "../../components/DownloadExcel/DownloadExcel";
import { fetch4, httpAdmin } from "../../Apis/commonApis";
import { CareTeamStatus } from "../../constants/constants";
import { AiFillPrinter } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import DatePicker from "react-datepicker";
import {
  compressImage,
  formatDate,
  getFullName,
  getMondayDate,
  getMonthAgoDate,
  handleDataPrint,
} from "../../utils/CommonFunctions";
import AwsLocation from "../../utils/AwsLocation";
import { ShowSwal } from "../../utils/alertSwal";
import { HiDownload } from "react-icons/hi";
import { IoMdInformationCircle } from "react-icons/io";
import careTeamXl from '../../files/careteamUpload.xlsx';
import { useDispatch, useSelector } from "react-redux";
import { createAccessEvent } from "../../redux-toolkit/reducer/eventLogsReducer";

let today = new Date();
let monthAgoDate = getMonthAgoDate();

function CareTeam() {

  const { accessRoles } = useSelector((state) => state.caresiteDataReducer);
  const currentModuleAccess = useMemo(() => {
    const findCurrentModule = accessRoles?.find(item => item.moduleName === 'CareTeam');
    return findCurrentModule?.children;
  }, [accessRoles]);

  const {
    register,
    handleSubmit,
    unregister,
    reset,
    formState: { errors },
  } = useForm();

  const {
    register: registerS,
    handleSubmit: handleSubmitS,
    reset: resetS,
    formState: { errors: errorsS },
    control: control,
  } = useForm();

  const handleDownloadClick = () => {
    const link = document.createElement('a');
    link.href = careTeamXl;
    link.download = 'careteamUpload.xlsx';
    link.click();
  };

  const [rowData, setRowData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [location, setlocation] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [locErrorMsg, setlocErrorMsg] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [refreshData, setRefreshData] = useState(0);

  const [filterType, setFilterType] = useState("week");
  const [dateRange, setDateRange] = useState([monthAgoDate, new Date()]);
  const [startDate, endDate] = dateRange;

  const dispatch = useDispatch();
  useEffect(() => {
    // create access event log
    const logData = {
      documentType: "careteam",
      description: "Careteam page visited",
    };
    dispatch(createAccessEvent(logData));
  }, []);

  function awsLocationHandler(locVal) {
    setlocation(locVal);
    if (locVal) setlocErrorMsg(false);
  }

  const resetForm = () => {
    reset();
    setlocErrorMsg(false);
    setlocation(null);
    setIsOpen(false);
  };
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  async function createcareTeam({
    lastName,
    firstName,
    email,
    DOB,
    uploadFile,
    weeklyPlannedHours,
    startDate,
  }) {
    if (!location) {
      setlocErrorMsg(true);
      return;
    }
    let formData = new FormData();
    formData.append("location", location?.Label);
    formData.append("latitude", location?.latitude);
    formData.append("longitude", location?.longitude);
    formData.append("DOB", DOB);
    formData.append("email", email);
    formData.append("first_Name", firstName);
    formData.append("last_Name", lastName);
    formData.append("careHomeId", currentCareSiteId);
    formData.append("timeZone", JSON.stringify(userTimezone));
    formData.append("weeklyPlannedHours", weeklyPlannedHours);
    formData.append("startDate", startDate);
    formData.append(
      "generalAvaliablity",
      JSON.stringify([
        { name: "monday", value: [] },
        { name: "tuesday", value: [] },
        { name: "wednesday", value: [] },
        { name: "thursday", value: [] },
        { name: "friday", value: [] },
        { name: "saturday", value: [] },
        { name: "sunday", value: [] },
      ])
    );
    if (uploadFile[0]) {
      let compressedImg = await compressImage(uploadFile);
      formData.append("image", compressedImg);
    }
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/createCareteamMember`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios(config).then(function (response) {
      ShowSwal(response.data.status, response.data.message);
      if (response.data.status) {
        resetForm();
        setRefreshData(Math.random());
        setIsOpen(false);
      }
    }).catch(function (error) {
      ShowSwal(false, 'Something went wrong, please try after sometime.')
    });
  }

  useEffect(() => {
    if (filterType === "custom" && endDate) {
      getCareTeamRowData(formatDate(startDate), formatDate(endDate));
    } else if (filterType === "week") {
      let mondayDate = getMondayDate();
      getCareTeamRowData(formatDate(mondayDate), formatDate(today));
    } else if (filterType === "today") {
      // getCareTeamRowData();
    }
  }, [filterType, dateRange, refreshData]);

  const [options, setoptions] = useState([]);

  const [selectedname, setselectedname] = useState([]);
  const [selectedId, setselectedId] = useState({});
  const [groupName, setgroupName] = useState("");
  const [ids, setIDs] = useState([]);

  const handleChange = (e) => {
    selectedname.push(e);
    let lastElement = selectedname[selectedname.length - 1];
    setselectedId(lastElement);
  };

  const createCareGroup = () => {
    let careteamId = [];
    let careteamName = [];

    selectedId.forEach((items, index) => {
      careteamId.push(items.value);
      careteamName.push(items.label);
    });
    let data = JSON.stringify({
      groupName: groupName,
      Name: careteamName,
      careHomeId: currentCareSiteId,
      careTeamId: careteamId,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/createCareteamGroup`,
      headers: {
        Authorization: localStorage.getItem("care_admin_token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config).then((response) => {
      swal(
        response.data.status ? "Success" : "Failed",
        response.data.message,
        response.data.status ? "success" : "error"
      ).then((value) => {
        // setIsOpen(false);
        // resetForm();
        setRefreshData(Math.random());
      });
    }).catch(function (error) {
      swal("Failed", "Oops, Something went wrong", "error");
    });
  };

  const getCareTeamRowData = (start, end) => {
    axios({
      url: `${process.env.REACT_APP_BASEURL}/getCareteam?careHomeId=${currentCareSiteId}&adminId=${user?._id}&startDate=${start}&endDate=${end}`,
      method: "GET",
      headers: { Authorization: localStorage.getItem("care_admin_token") },
    })
      .then((res) => {
        let CareTeamData = res?.data?.careteamListing
          ? res?.data?.careteamListing
          : [];
        let careteamListing = [];
        let teamDownloadData = [];

        CareTeamData.forEach((element, index) => {
          const totalDaysWorkedTillNow = element.performance.length;
          let scoreTillNow = element.performance?.reduce(
            (acc, obj) => +acc + +obj?.score,
            0
          );
          const averagePerformance = (
            scoreTillNow / totalDaysWorkedTillNow
          ).toFixed(2);

          careteamListing.push({
            id: element?._id,
            careHomeId: element?.careHomeId || "",
            citizenship: element?.citizenship || "",
            careMemberstatus: element?.careMemberstatus || "",
            email: element?.email || "",
            performance:
              element?.performance?.length > 0 ? averagePerformance : 0,
            hrsWorked: element?.hrs_of_service ? element?.hrs_of_service : "0",
            contractStartdate: datechangeHandler(element?.startDate),
            first_Name: element?.first_Name || "",
            name: getFullName(element),
            image: element?.image,
            last_Name: element?.last_Name,
            location: element?.location || "",
            overdueTasks: element?.overdueTasks ? element?.overdueTasks : "0",
            teamNotes: element?.careNotes ?? "0",
            taskDone: element?.completedTasks ?? "0",
            toDos: element?.toDos ? element?.toDos : "0",
            arrow: "",
          });
          teamDownloadData.push({
            ['First Name']: element.first_Name,
            ['Last Name']: element.last_Name ?? '',
            ['Job Title']: element.jobTitle ?? '',
            ['Qualification']: element.qualification ?? '',
            ['DOB']: element.DOB ?? '',
            ['Location']: element.location ?? '',
            ['Contact Number']: element.mobileNo ?? '',
            ['Email']: element.email ?? '',
            ['Marital Status']: element.marital_Status ?? '',
            ['religion']: element.religion ?? '',
            ['Citizenship']: element.citizenship ?? '',
            ['Laguages']: element.language ?? '',
            ['Employment Start Date']: element.startDate ?? '',
            ['Employment End Date']: element.endDate ?? '',
            ['Weekly Normal Hours Threshold']: element.weeklyPlannedHours ?? '',
          })
        });
        setRowData(careteamListing);
        let option = [];
        CareTeamData.forEach((element, index) => {
          option.push({ value: element._id, label: element.first_Name });
        });
        setoptions(option);
        setDownloadData(teamDownloadData);
      })
      .catch((error) => console.log(`Error: ${error}`));
  };

  let user = JSON.parse(localStorage.getItem("userData"));

  function selectedIDs(ids) {
    setIDs(ids);
  }

  // -----------  Delete Clients Popup (Show and Hide Handler) Starts ------------ //

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleCloseDeletePopup = () => setIsDeleteOpen(false);
  const handleShowDeletePopup = () => setIsDeleteOpen(true);

  // ---------------------     delete multiple clients api    ----------------------------------  //

  const deletemultiple = async () => {
    const res = await httpAdmin(`deleteCareTeam`, 'post', { careTeamId: ids, isActive: false }, null);
    if (res.status) {
      handleCloseDeletePopup();
      setRefreshData(Math.random());
    }
    ShowSwal(res.status, res.message);
  }

  // -----------  Change clients Status Popup (Show and Hide Handler) Starts ------------ //

  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const handleCloseStatusPopup = () => setIsStatusOpen(false);
  const handleShowStatusPopup = () => setIsStatusOpen(true);

  // ---------------------  Change client status api    ----------------------------------  //

  const changeCareTeamStatus = async ({ careMemberstatus }) => {
    const data = {
      careTeamId: ids,
      careMemberstatus: careMemberstatus.value,
    };
    const res = await httpAdmin(`markStatusCareTeam`, 'post', data, null);
    if (res.status) {
      handleCloseStatusPopup();
      setRefreshData(Math.random());
    }
    ShowSwal(res.status, res.message);
  }

  function customDateChangeHandler(update) {
    setDateRange(update);
  }

  const fileInputRef = useRef(null);
  const openFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const [loader, setLoader] = useState(false);
  function onFileUpload(e) {
    let file = e.target?.files[0];
    if (file) {
      setLoader(true);
      uploadFileHandler(file)
    }
  }

  const uploadFileHandler = async (file) => {
    let body = new FormData();
    body.append("file", file);
    body.append("adminId", user?._id);
    body.append("careHomeId", currentCareSiteId);
    const result = await httpAdmin(`uploadCareteam`, "post", body, 'formdata');
    setLoader(false);
    setRefreshData(Math.random());
    ShowSwal(result.status, result.message);
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              {/* <h4 className="card-title">
                    Care Team
                  </h4> */}
              <div className="row mb-2 justify-content-end">
                <div className="col-md-2">
                  <AiFillPrinter
                    className="me-3 ms-1 curser_point"
                    onClick={() => handleDataPrint(downloadData)}
                  />
                  <a className="curser_point me-3">
                    <JsonDownload
                      fileName="careteams-excel"
                      downloadfile={downloadData}
                    />
                  </a>
                  <a className="curser_point upload_icon">
                    {!loader && <input
                      type="file"
                      id="upLoader"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      name="images"
                      ref={fileInputRef}
                      onChange={onFileUpload}
                    />}
                    {/* <HiDownload className="uploadIcon" /> */}
                  </a>
                </div>
                <div className="col-md-4"></div>
                <div className="searchForm careSearch">
                  <input
                    type="text"
                    className="form-control"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    placeholder="Search"
                  />
                  <BsSearch className="icon me-2" />
                </div>
                <div className="d-flex col-md-6 action_mt justify-content-end">
                  {/* <div className="col-md-7 col-xl-6 col-xxl-5 d-flex justify-content-center">
        
                      </div> */}
                  <div className="col-md-6 d-flex justify-content-start heightSetCustom">
                    <div className="date_filters">
                      <ul className="filter_box me-2 mb-1">
                        {/* <NavLink
                              exact
                              activeClassName={filterType === "today" ? "active" : ""}
                              to="#"
                              onClick={() => setFilterType("today")}
                            >
                              Today
                            </NavLink> */}
                        <NavLink
                          activeClassName={
                            filterType === "week" ? "active " : ""
                          }
                          to="#"
                          onClick={() => setFilterType("week")}
                        >
                          This Week
                        </NavLink>
                        <NavLink
                          activeClassName={
                            filterType === "custom" ? "active" : ""
                          }
                          to="#"
                          onClick={() => setFilterType("custom")}
                        >
                          Custom
                        </NavLink>
                      </ul>
                    </div>
                    {filterType === "custom" ? (
                      <div className="col-md-3 mb-2 datepicker_input">
                        <DatePicker
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(update) => {
                            customDateChangeHandler(update);
                          }}
                          isClearable={true}
                        />
                      </div>
                    ) : (
                      <div className="col-md-3 mb-2">&ensp;</div>
                    )}
                  </div>
                  <div className="col-md-4 col-xl-3 btns_head">
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Actions"
                    >
                      <Dropdown.Item
                        disabled={currentModuleAccess?.[0]?.access !== "full"}
                        onClick={() => {
                          setIsOpen(true);
                        }}
                      >
                        Add Team Member
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                            data-bs-toggle="modal"
                            data-bs-target="#createGroup"
                            disabled={
                              creategroups.access != "full" ||
                                user.ownerId?.SubscriptionPlan === "baseplan" ||
                                user?.SubscriptionPlan === "baseplan"
                                ? true
                                : false
                            }
                          >
                            Create Groups
                          </Dropdown.Item> */}

                      <Dropdown.Item
                        disabled={currentModuleAccess?.[0]?.access !== "full"}
                        onClick={() => {
                          if (ids.length >= 1) handleShowDeletePopup(true);
                          else
                            swal(
                              "Failed",
                              "Please Select at least one client",
                              "error"
                            ).then((value) => {
                              return;
                            });
                        }}
                      >
                        Delete{" "}
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={currentModuleAccess?.[0]?.access !== "full"}
                        onClick={() => {
                          if (ids.length > 0) handleShowStatusPopup(true);
                          else
                            swal(
                              "Failed",
                              "Please Select at least one client",
                              "error"
                            ).then((value) => {
                              return;
                            });
                        }}
                      >
                        Change Status{" "}
                      </Dropdown.Item>
                      <div className="position-relative">
                        <Dropdown.Item onClick={openFileInput}
                          disabled={currentModuleAccess?.[0]?.access !== "full"}>
                          Import CareTeam
                        </Dropdown.Item>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="tooltip-info">Download sample file (Please enter details in small caps)</Tooltip>}
                        >
                          <span style={{
                            cursor: 'pointer', cursor: 'pointer',
                            position: 'absolute',
                            right: '6px',
                            top: '49%',
                            transform: 'translate(0%, -52%)'
                          }} onClick={handleDownloadClick}>
                            <IoMdInformationCircle color="#9e61e7" />
                          </span>
                        </OverlayTrigger>
                      </div>

                    </DropdownButton>
                  </div>
                </div>
                {/* <InvoiceActions selectedData={selectedData} getLatestInvioceData={getLatestInvioceData} /> */}
              </div>
              <div style={{ flexGrow: 1 }}>
                <CareTeamNewTable
                  searchText={searchText.trim().toLowerCase()}
                  rows={rowData}
                  selectedIDs={selectedIDs}
                />
              </div>
            </div>
          </div>
        </div>
        {/* delete popup */}
        <Modal
          className="viewModal"
          show={isDeleteOpen}
          onHide={handleCloseDeletePopup}
        >
          <Modal.Header closeButton2>
            <Modal.Title>
              <span>Delete Care Team</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <p>
                    Are you sure you want to delete this{" "}
                    {ids.length > 1 ? "care team members" : "care team member"}{" "}
                    ?
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <button
                className="btn submitBtn"
                onClick={handleCloseDeletePopup}
              >
                No
              </button>
              <button
                className="btn submitBtn"
                onClick={() => {
                  deletemultiple();
                }}
              >
                Yes
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        {/* create groups  */}
        <div>
          <div
            className="modal fade"
            id="createGroup"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Create Group
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body" style={{ minHeight: "400px" }}>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label className="form-label">Group Name</label>
                      <input
                        type="text"
                        value={groupName}
                        onChange={(e) => setgroupName(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <label className="form-label">Member Name</label>

                      <div className="position-relative">
                        {/* <input value="" type="text" className="form-control" /> */}
                        <div className="hint_box" style={{ display: "block" }}>
                          <Select
                            options={options}
                            isMulti
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={createCareGroup}
                    className="btn btn-success"
                  >
                    Create Group
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* add care team members  */}
        <Modal
          show={isOpen}
          onHide={() => {
            setIsOpen(false);
          }}
        >
          <Modal.Body>
            <div
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-scrollable modal-lg">
                <form onSubmit={handleSubmit(createcareTeam)}>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Add Team Member
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                          resetForm();
                        }}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">First Name</label>
                          <input
                            // value={firstName}
                            // onChange={(e) => setFirstName(e.target.value)}
                            type="text"
                            className="form-control"
                            {...register("firstName", {
                              required: true,
                              pattern: /^[A-Za-z ]+$/i,
                              min: 1,
                            })}
                          />
                          {errors?.firstName?.type === "pattern" && (
                            <p className="error_style">Alphabet only</p>
                          )}
                          {errors?.firstName?.type === "required" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                          {errors?.firstName?.type === "min" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Last Name</label>
                          <input
                            // value={lastName}
                            // onChange={(e) => setlastName(e.target.value)}
                            type="text"
                            className="form-control"
                            {...register("lastName", {
                              required: true,
                              pattern: /^[A-Za-z ]+$/i,
                              min: 1,
                            })}
                          />
                          {errors?.lastName?.type === "pattern" && (
                            <p className="error_style">Alphabet only</p>
                          )}
                          {errors?.lastName?.type === "required" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                          {errors?.lastName?.type === "min" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-4">
                          <label className="form-label"> Email</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              {...register("email", {
                                required: true,
                                pattern:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              })}
                            />
                            {errors?.email?.type === "pattern" && (
                              <p className="error_style">
                                Invalid email address
                              </p>
                            )}
                            {errors?.email?.type === "required" && (
                              <p className="error_style">
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-12 mb-4">
                          <label className="form-label">Password</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              type={pvisibility ? "text" : "password"}
                              autoComplete="new-password"
                              {...register("password", {
                                required: "This field is required",
                                minLength: {
                                  value: 6,
                                  message: "Please enter minimum 6 characters",
                                },
                              })}
                            />
                            {errors.password && (
                              <p role="alert" className="error_style">
                                {errors.password.message}
                              </p>
                            )}
                            <div style={{ display: "block" }}></div>
                          </div>
                        </div> */}

                        <div className="col-md-12 mb-3">
                          <label className="form-label">Date of Birth</label>
                          <input
                            type="date"
                            className="form-control"
                            {...register("DOB", {
                              required: true,
                            })}
                          />
                          {errors?.DOB?.type === "required" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Location</label>
                          <AwsLocation awsLocationHandler={awsLocationHandler} />
                          {locErrorMsg && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Image</label>
                          <input
                            type="file"
                            accept="image/*"
                            className="form-control"
                            {...register("uploadFile")}
                          />

                          {errors?.uploadFile?.type === "required" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            Weekly Normal Hours Threshold
                          </label>
                          <input
                            // value={firstName}
                            // onChange={(e) => setFirstName(e.target.value)}
                            type="text"
                            className="form-control"
                            {...register("weeklyPlannedHours", {
                              required: true,
                              pattern: /\d+/,
                              min: 1,
                            })}
                          />
                          {errors?.weeklyPlannedHours?.type === "pattern" && (
                            <p className="error_style">Numbers only</p>
                          )}
                          {errors?.weeklyPlannedHours?.type === "required" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-1">
                          <label className="form-label">
                            Employment Start Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            {...register("startDate", {
                              required: true,
                            })}
                          />
                          {errors?.startDate?.type === "required" && (
                            <p className="error_style">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-success">
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* status change  */}
        <Modal
          className="viewModal"
          show={isStatusOpen}
          onHide={handleCloseStatusPopup}
        >
          <form onSubmit={handleSubmitS(changeCareTeamStatus)}>
            <Modal.Header closeButton2>
              <Modal.Title>
                <span>
                  Change{" "}
                  {ids.length > 1 ? "Careteam Members" : "Careteam Member"}{" "}
                  Status
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label className="form-label">Type</label>
                  {/* React Select Along with React Hook Form */}
                  <Controller
                    control={control}
                    name="careMemberstatus"
                    rules={{ required: "status is required" }}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          isClearable
                          isSearchable={false}
                          className="react-dropdown"
                          classNamePrefix="dropdown"
                          options={CareTeamStatus}
                        // onChange={handleStatusChange}
                        />
                      );
                    }}
                  />
                  <p className="error_style">
                    {errorsS.careMemberstatus?.message ||
                      errors.careMemberstatus?.label.message}
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <button
                  type="button"
                  className="btn submitBtn"
                  onClick={handleCloseStatusPopup}
                >
                  Close
                </button>
                <button type="submit" className="btn submitBtn">
                  Save
                </button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </>
  );
}

export default CareTeam;
