
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Invoices from "../../components/invoiceTemplate/adminInvoicetemplate";
import AutoGenerateInvoiceTable from "./autogenerateInvoice";
import InvoiceConfig from './InvoiceConfig';
import { useDispatch } from 'react-redux';
import { createAccessEvent } from '../../redux-toolkit/reducer/eventLogsReducer';

export default function InvoiceRoutes(props) {
    const { filterType, dateRange, searchText } = props;
    let { topicId } = useParams();

    const [specificInvoiceId, setSpecificInvoiceId] = useState("");
    const [showinvoiceTemplate, setShowInvoiceTemplate] = useState("");

    const dispatch = useDispatch();
    useEffect(() => {
      // create access event log
      const logData = {
        documentType: `invoice`,
        description: `Invoice page visited`,
        // documentId: careteamId,
      };
      dispatch(createAccessEvent(logData));
    }, [topicId]);

    function invoiceTemplateHandler(type, selected) {
        setSpecificInvoiceId(selected?._id);
        setShowInvoiceTemplate(type);
    }

    return (
        <>
            {(topicId === "invoices") && <>
                {showinvoiceTemplate === "edit" && (
                    <>
                        <a
                            className="medicine_back"
                            onClick={() => setShowInvoiceTemplate("")}
                        >
                            {" "}
                            Back{" "}
                        </a>
                        <Invoices
                            invoiceId={specificInvoiceId}
                        />
                    </>
                )}
                {showinvoiceTemplate !== "edit" && (
                    <AutoGenerateInvoiceTable
                        searchText={searchText}
                        filterType={filterType}
                        dateRange={dateRange}
                        invoiceTemplateHandler={invoiceTemplateHandler}
                    />
                )}
            </>}

            {topicId === "configure" &&
                <InvoiceConfig searchText={searchText} />
            }
        </>
    )
}