import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import "../Dashboard/dashboard.css";
import Donut from "./tasks-graph";
import { httpAdmin } from "../../Apis/commonApis";
import DatePicker from "react-datepicker";
// import ReactHookForm from "../../Form/React-libraries/ReactHookForm";
// import LocationSearchInput from '../../Form/geolocation'
import { currentCareSiteId } from "../../redux-toolkit/reducer/switchSites";
import {
  formatDate,
  getFullName,
  getMondayDate,
  getMonthAgoDate,
} from "../../utils/CommonFunctions";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { createAccessEvent } from "../../redux-toolkit/reducer/eventLogsReducer";

let today = new Date();
let monthAgoDate = getMonthAgoDate();

const chatUserImg = "/images/chatUser.svg";

function Dashboard() {
  const dispatch = useDispatch();
  const { currentPlanName, accessRoles } = useSelector(
    (state) => state.caresiteDataReducer
  );
  // const currentModuleAccess = useMemo(() => {
  //   const findCurrentModule = accessRoles?.find(item => item.moduleName === 'Dashboard');
  //   return findCurrentModule;
  // }, [accessRoles]);

  const [dashboardDetails, setDashboardDetails] = useState([]);
  const [alertAndReminder, setAlertAndReminder] = useState([]);
  const [overdueTask, setOverdueTask] = useState([]);
  const [incidentReports, setIncidentReports] = useState([]);
  const [taskSeries, setTaskSeries] = useState([0, 0, 0, 0, 0, 0]);
  const [displayDate, setDisplayDate] = useState({ start: "", end: "" });
  const [hoverOvdueTasks, setHoverOvdueTasks] = useState(null);
  const [hoverInciReports, setHoverInciReports] = useState(null);
  const [filterType, setFilterType] = useState("today");

  const [dateRange, setDateRange] = useState([monthAgoDate, new Date()]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    // create access event log
    const logData = {
      documentType: "dashboard",
      description: "Dashboard visited successfully",
    };
    dispatch(createAccessEvent(logData));
  }, []);

  useEffect(() => {
    let careHomeId = localStorage.getItem("carehomeId");
    let currentId = currentCareSiteId ? currentCareSiteId : careHomeId;
    if (currentId) {
      if (filterType === "custom" && endDate) {
        setDisplayDate({
          start: startDate.toLocaleDateString(undefined, dateOptions),
          end: endDate.toLocaleDateString(undefined, dateOptions),
        });
        getDashboardDetails(
          currentId,
          formatDate(startDate),
          formatDate(endDate)
        );
        getAlertsReminders(
          currentId,
          formatDate(startDate),
          formatDate(endDate)
        );
      } else if (filterType === "week") {
        let mondayDate = getMondayDate();
        setDisplayDate({
          start: mondayDate.toLocaleDateString(undefined, dateOptions),
          end: today.toLocaleDateString(undefined, dateOptions),
        });
        getDashboardDetails(
          currentId,
          formatDate(mondayDate),
          formatDate(today)
        );
        getAlertsReminders(
          currentId,
          formatDate(mondayDate),
          formatDate(today)
        );
      } else if (filterType === "today") {
        setDisplayDate({
          start: "",
          end: today.toLocaleDateString(undefined, dateOptions),
        });
        getDashboardDetails(currentId, formatDate(today), formatDate(today));
        getAlertsReminders(currentId, formatDate(today), formatDate(today));
      }
    }
  }, [filterType, dateRange, accessRoles]);

  const getDashboardDetails = async (cid, start, end) => {
    const res = await httpAdmin(
      `getDashboardDetails?careSiteId=${cid}&startDate=${start}&endDate=${end}`,
      "get",
      null,
      null
    );
    if (res.status) {
      let details = res.data;
      let filterOverdueData = filterDuplicatetasks(details?.overdue);
      setOverdueTask(filterOverdueData);
      let filterIncidentData = filterDuplicatetasks(details?.reportIncident);
      setIncidentReports(filterIncidentData);
      setDashboardDetails(details);
      setTaskSeries([
        details?.completed ?? 0,
        details?.inProgress ?? 0,
        details?.pending ?? 0,
        details.overdue?.length ?? 0,
        details?.omitted ?? 0,
      ]);
    }
  };

  function filterDuplicatetasks(taskData) {
    let allTasks = [];
    taskData?.forEach((elem) => {
      let tasksClient = elem.shiftData
        ? elem.shiftData?.clientId
        : elem?.clientId;
      let tasksCareTeam = elem.shiftData
        ? elem.shiftData?.careTeamId
        : elem?.careTeamId;
      let combId = `${tasksClient?._id}-${tasksCareTeam?._id}`;
      let clientName = getFullName(tasksClient);
      let careTeamName = getFullName(tasksCareTeam);
      allTasks.push({
        combId: combId,
        task: elem.shiftData?.Task ?? elem.Task,
        date: elem.shiftData?.Date ?? elem.Date,
        clientName: clientName,
        careTeamName: careTeamName,
        clientImg: tasksClient?.image,
        careTeamImg: tasksCareTeam?.image,
      });
    });
    const duplicateIds = {};
    const uniqueArray = allTasks.filter((item) => {
      return duplicateIds.hasOwnProperty(item.combId)
        ? false
        : (duplicateIds[item.combId] = true);
    });
    let filteredData = filterTasks(allTasks, uniqueArray);
    return filteredData;
  }

  function filterTasks(allTasks, Unique) {
    const filteredArray = Unique.map((item) => {
      const relatedItem = allTasks.filter(
        (secondItem) => secondItem.combId === item.combId
      );
      const totalRelated = relatedItem.length;
      const relatedTask = relatedItem.map((item) => item?.task?.[0]);
      return { ...item, totalRelated, relatedTask };
    });
    return filteredArray;
  }

  const getAlertsReminders = async (cid, start, end) => {
    const res = await httpAdmin(
      `getAlertsAndReminders?careSiteId=${cid}&startDate=${start}&endDate=${end}`,
      "get",
      null,
      null
    );
    if (res.status) {
      let data = res.data;
      // data?.sort(compareTime);
      setAlertAndReminder(data);
    }
  };

  function customDateChangeHandler(update) {
    setDateRange(update);
  }
  function compareTime(a, b) {
    const timeA = new Date(a.createdAt);
    const timeB = new Date(b.createdAt);

    if (timeA < timeB) {
      return 1;
    } else if (timeA > timeB) {
      return -1;
    } else {
      return 0;
    }
  }

  const approveLeaveAndShift = async (endpoint, body) => {
    const res = await httpAdmin(endpoint, "post", body, null);
    if (res.status) {
      let appfilter = filterType;
      setFilterType("");
      setFilterType(appfilter);
    } else {
      swal("Failed", res.message, "error");
    }
  };

  function handleAlertClick(action, data) {
    let body = {};
    let apiEndPoint = "";
    let acceptedOrDeclined = action === "Accept";
    if (data.message.includes("has applied for the openshift")) {
      body = {
        alertId: data._id,
        shiftId: data.shiftId[0],
        careTeamId: data.careTeamId,
        status: acceptedOrDeclined,
      };
      apiEndPoint = "approveOpenshift";
    } else {
      body = {
        alertId: data._id,
        careTeamId: data.careTeamId,
        careSiteId: data.careHomeId,
        leaveId: data.leaveId,
        leaveStatus: acceptedOrDeclined,
      };
      apiEndPoint = "approveLeave";
    }
    swal({
      title: "Are you sure??",
      text: `click on Yes to ${action}`,
      buttons: {
        cancel: "Cancel",
        succeed: "Yes",
      },
    }).then((result) => {
      if (result) {
        approveLeaveAndShift(apiEndPoint, body);
      } else {
        console.log("rejected");
      }
    });
  }

  const handleMouseEnterOverdue = (data) => {
    setHoverOvdueTasks({
      total: data.totalRelated,
      client: data.clientName,
      careTeam: data.careTeamName,
    });
  };

  const handleMouseLeaveOverdue = () => {
    setHoverOvdueTasks(null);
  };

  const handleMouseEnterIncident = (data) => {
    setHoverInciReports({
      total: data.totalRelated,
      client: data.clientName,
      careTeam: data.careTeamName,
    });
  };

  const handleMouseLeaveIncident = () => {
    setHoverInciReports(null);
  };
  let percentCompleted =
    (dashboardDetails?.completed / dashboardDetails?.allTasks) * 100;
  percentCompleted = percentCompleted ? percentCompleted.toFixed(2) : "0.00";
  // const taskSeries = [dashboardDetails?.allTasks, dashboardDetails?.completed, dashboardDetails?.inProgress, dashboardDetails?.pending, dashboardDetails.overdue?.length, dashboardDetails?.omitted];

  const dateOptions = { day: "numeric", month: "long", year: "numeric" };

  const isPlanBase = currentPlanName === "Base";

  return (
    <>
      <div className="page-wrapper paddingTopNew">
        <div className="container-fluid min_height">
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className={`row col-md-12 ${"justify-content-around"}`}>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6"></div>

                  <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="col-md-12 d-flex justify-content-start heightSetCustom">
                      <div className="date_filters">
                        <ul className="filter_box me-2 mb-1">
                          <NavLink
                            exact
                            activeClassName={
                              filterType === "today" ? "active" : ""
                            }
                            to="#"
                            onClick={() => setFilterType("today")}
                          >
                            Today
                          </NavLink>
                          <NavLink
                            activeClassName={
                              filterType === "week" ? "active " : ""
                            }
                            to="#"
                            onClick={() => setFilterType("week")}
                          >
                            This Week
                          </NavLink>
                          <NavLink
                            activeClassName={
                              filterType === "custom" ? "active" : ""
                            }
                            to="#"
                            onClick={() => setFilterType("custom")}
                          >
                            Custom
                          </NavLink>
                        </ul>
                      </div>
                      {filterType === "custom" ? (
                        <div className="col-md-3 mb-2 datepicker_input">
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                              customDateChangeHandler(update);
                            }}
                            isClearable={true}
                          />
                        </div>
                      ) : (
                        <div className="col-md-3 mb-2 me-4">&ensp;</div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <ReactHookForm /> */}
                {/* <LocationSearchInput /> */}

                <div className={`row col-md-12 ${"justify-content-around"}`}>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="">
                      <div className="card admin_dashboard_card card_border">
                        <div className="card-body">
                          <h4 className="card-title">All Tasks</h4>
                          <div className="dashboard_graph_box">
                            <ul className="col-md-6">
                              <li>
                                All Tasks :{" "}
                                <strong style={{ color: "#c580d1" }}>
                                  {dashboardDetails?.allTasks}
                                </strong>
                              </li>
                              <li>
                                Completed :{" "}
                                <strong style={{ color: "#219653" }}>
                                  {taskSeries[0]}
                                </strong>
                              </li>
                              <li>
                                In-Process :{" "}
                                <strong style={{ color: "#007fff" }}>
                                  {taskSeries[1]}
                                </strong>
                              </li>
                              <li>
                                Pending :{" "}
                                <strong style={{ color: "#818181" }}>
                                  {taskSeries[2]}
                                </strong>
                              </li>
                              <li>
                                Overdue :{" "}
                                <strong style={{ color: "#eb5757" }}>
                                  {taskSeries[3]}
                                </strong>
                              </li>
                              <li>
                                Omitted :{" "}
                                <strong style={{ color: "#F2C94C" }}>
                                  {taskSeries[4]}
                                </strong>
                              </li>
                            </ul>
                            <div className="col-md-5 dashboard_graph_box_in">
                              <Donut
                                color={[
                                  "#219653",
                                  "#2f80ed",
                                  "#818181",
                                  "#eb5757",
                                  "#F2C94C",
                                ]}
                                series={taskSeries}
                              />{" "}
                              <span className="count">
                                {percentCompleted}% <br />
                                Completed
                              </span>
                            </div>
                          </div>
                          <NavLink
                            to={{
                              pathname: "/admin/shift",
                              state: {
                                fromDashboard: true,
                                filterType: filterType,
                                dateRange: dateRange,
                                filter: "all",
                              },
                            }}
                            className="link"
                          >
                            See All
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="card admin_dashboard_card card_border">
                        <div className="position-relative">
                          <div
                            id="box"
                            className="hidden-box"
                            style={{
                              display: hoverOvdueTasks ? "block" : "none",
                            }}
                          >
                            <p className=" mb-0">
                              {" "}
                              Total : {hoverOvdueTasks?.total}
                            </p>
                            <p className=" mb-0">
                              {" "}
                              Client : {hoverOvdueTasks?.client}
                            </p>
                            <p className=" mb-0">
                              {" "}
                              Care Team : {hoverOvdueTasks?.careTeam}
                            </p>
                          </div>
                        </div>
                        <div className="card-body">
                          <h4 className="card-title">Overdue Tasks</h4>
                          <p className="task">
                            No. of Tasks:{" "}
                            <strong>{dashboardDetails.overdue?.length}</strong>
                          </p>
                          <p className="theme_color down_arrow">Clients </p>
                          <div className="clint_list_scroll">
                            {overdueTask?.map((item) => {
                              return (
                                <ul
                                  key={item.combId}
                                  onMouseEnter={() =>
                                    handleMouseEnterOverdue(item)
                                  }
                                  onMouseLeave={handleMouseLeaveOverdue}
                                >
                                  <li className="match_box mb-1">
                                    <img
                                      alt=""
                                      src={
                                        item.clientImg
                                          ? item.clientImg
                                          : chatUserImg
                                      }
                                    />
                                  </li>
                                  <li>
                                    <img
                                      className={`${
                                        item.careTeamImg?.includes("chatUser")
                                          ? "forImagecareteam"
                                          : ""
                                      }`}
                                      alt=""
                                      src={
                                        item.careTeamImg
                                          ? item.careTeamImg
                                          : chatUserImg
                                      }
                                    />
                                  </li>
                                </ul>
                              );
                            })}
                          </div>
                          {/* <NavLink
                            className="nav-link dropdown-toggle waves-effect waves-dark"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                          </NavLink> */}
                          <p className="theme_color up_arrow">
                            Assigned Care Team{" "}
                          </p>
                          <NavLink
                            to={{
                              pathname: "/admin/shift",
                              state: {
                                fromDashboard: true,
                                filterType: filterType,
                                dateRange: dateRange,
                                filter: "overdue",
                              },
                            }}
                            className="link"
                          >
                            See All
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="">
                      <div className="card admin_dashboard_card card_border">
                        <div className="position-relative">
                          <div
                            id="box"
                            className="hidden-box"
                            style={{
                              display: hoverInciReports ? "block" : "none",
                            }}
                          >
                            <p className=" mb-0">
                              {" "}
                              Total : {hoverInciReports?.total}
                            </p>
                            <p className=" mb-0">
                              {" "}
                              Client : {hoverInciReports?.client}
                            </p>
                            <p className=" mb-0">
                              {" "}
                              Care Team : {hoverInciReports?.careTeam}
                            </p>
                          </div>
                        </div>
                        <div className="card-body">
                          <h4 className="card-title">Incidents Reported</h4>
                          <p className="task">
                            No. of Incidents:{" "}
                            <strong>
                              {dashboardDetails.reportIncident?.length}{" "}
                            </strong>
                          </p>
                          <p className="theme_color down_arrow">Clients </p>
                          <div className="clint_list_scroll">
                            {incidentReports?.map((item) => {
                              return (
                                <ul
                                  key={item.combId}
                                  onMouseEnter={() =>
                                    handleMouseEnterIncident(item)
                                  }
                                  onMouseLeave={handleMouseLeaveIncident}
                                >
                                  <li className="match_box mb-1">
                                    <img
                                      alt=""
                                      src={
                                        item.clientImg
                                          ? item.clientImg
                                          : chatUserImg
                                      }
                                    />
                                  </li>
                                  <li>
                                    <img
                                      className={`${
                                        item.careTeamImg?.includes("chatUser")
                                          ? "forImagecareteam"
                                          : ""
                                      }`}
                                      alt=""
                                      src={
                                        item.careTeamImg
                                          ? item.careTeamImg
                                          : chatUserImg
                                      }
                                    />
                                  </li>
                                </ul>
                              );
                            })}
                          </div>
                          <p className="theme_color up_arrow">
                            Assigned Care Team{" "}
                          </p>
                          <NavLink
                            to={{
                              pathname: "/admin/shift",
                              state: {
                                fromDashboard: true,
                                filterType: filterType,
                                dateRange: dateRange,
                                filter: "incident",
                              },
                            }}
                            className="link"
                          >
                            See All
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="card admin_dashboard_card card_border">
                        <div className="card-body">
                          <h4 className="card-title">
                            Alerts & Reminders
                            {/* <NavLink className="float-end" to="/admin/dashboard">
                          <BsFilterSquare />
                        </NavLink> */}
                          </h4>
                          <p className="task time d-flex justify-content-between">
                            <strong>
                              {filterType === "today" ? "Today" : "."}
                            </strong>
                            <span>
                              {displayDate.start + " - " + displayDate.end}
                            </span>
                          </p>
                          <div className="notification_scroll">
                            <ul className="notification_ul">
                              {!isPlanBase &&
                                alertAndReminder?.length > 0 &&
                                alertAndReminder
                                  ?.sort(compareTime)
                                  ?.map((item) => {
                                    const imageIcon =
                                      item.image ??
                                      "/images/defaultAlertImg.svg";
                                    const imageType =
                                      item.userType === "caresite"
                                        ? "admin"
                                        : item.userType;
                                    return (
                                      <li key={item._id}>
                                        <a>
                                          <span>
                                            {filterType === "today"
                                              ? new Date(
                                                  item.createdAt
                                                ).toLocaleTimeString()
                                              : new Date(
                                                  item.createdAt
                                                ).toLocaleString("en-GB", {
                                                  timeZone: "UTC",
                                                })}
                                          </span>
                                          <div className="d-flex">
                                            <img
                                              alt=""
                                              src={imageIcon}
                                              className={
                                                imageIcon.includes("chatUser")
                                                  ? `forImage${imageType}`
                                                  : ""
                                              }
                                            />
                                            <span className="mb-0">
                                              {item.message}
                                            </span>
                                            <img
                                              alt=""
                                              className="me-0 ms-auto"
                                              src={`/images/notification_${
                                                item.type === "alert"
                                                  ? "2"
                                                  : "4"
                                              }.svg`}
                                            />
                                          </div>
                                          {item?.message?.includes(
                                            "has applied for"
                                          ) &&
                                            (item.status !== "false" ? (
                                              <div className="alert_status">
                                                <button
                                                  className={`btn ${
                                                    item.status === "Accepted"
                                                      ? "btn-success"
                                                      : "btn-danger"
                                                  }`}
                                                >
                                                  {item.status}
                                                </button>
                                              </div>
                                            ) : (
                                              <div className="alert_btns">
                                                <button
                                                  className="btn"
                                                  onClick={() =>
                                                    handleAlertClick(
                                                      "Accept",
                                                      item
                                                    )
                                                  }
                                                >
                                                  Accept
                                                </button>
                                                <button
                                                  className="btn"
                                                  onClick={() =>
                                                    handleAlertClick(
                                                      "Decline",
                                                      item
                                                    )
                                                  }
                                                >
                                                  Decline
                                                </button>
                                              </div>
                                            ))}
                                        </a>
                                      </li>
                                    );
                                  })}
                              {isPlanBase && (
                                <li className="d-flex justify-content-center">
                                  {`This feature is not available in the ${currentPlanName} Plan`}
                                </li>
                              )}
                              {!isPlanBase && alertAndReminder?.length < 1 && (
                                <li className="d-flex justify-content-center">
                                  No data to show
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
